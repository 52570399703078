import style from '../TextsAdmin.module.scss'
import { TextAdmin } from '../../../../types/types'
import { topTextsMethod } from '../TextsAdmin.methods'

interface Props {
  textsAdmin: TextAdmin[]
}

export const AdminStatistics = (props: Props) => {
  const textsGuessedPercent =
    props.textsAdmin.reduce((acc, text) => {
      if (text.guesses.total > 0) {
        return acc + (text.guesses.correct / text.guesses.total) * 100
      } else {
        return acc + 0
      }
    }, 0) / props.textsAdmin.length

  const iqPercent =
    props.textsAdmin.reduce((acc, text) => {
      if (text.guessesFrom.total > 0) {
        return acc + (text.guessesFrom.correct / text.guessesFrom.total) * 100
      } else {
        return acc + 0
      }
    }, 0) / props.textsAdmin.length

  const realPlayers = props.textsAdmin.filter((text) => !text.hasTwinks).length
  const totalTexts = props.textsAdmin.length
  const textsPerPlayer = totalTexts / realPlayers

  const topTexts = topTextsMethod(props.textsAdmin)

  const averageNominations = props.textsAdmin.reduce((acc, text) => acc + text.nominations.length, 0) / props.textsAdmin.length

  return (
    <>
      <h2>Статистика</h2>
      <div className={style.totals}>
        <div className={style.totalBlock}>
          <h3>Братская могила</h3>
          <div className={style.description}>Помянем наших братьев и сестёр</div>
          <div className={style.icon}>💀</div>
          <div className={style.count}>{props.textsAdmin.filter((text) => text.rating === 0).length}</div>
        </div>
        <div className={style.totalBlock}>
          <h3>Рецензии</h3>
          <div className={style.description}>Тут нет пояснения, но вёрстка есть вёрстка</div>
          <div className={style.icon}>✉️</div>
          <div className={style.count}>{props.textsAdmin.reduce((acc, text) => acc + text.reviewsTexts.length, 0)}</div>
        </div>

        <div className={style.totalBlock}>
          <h3>Угадано текстов</h3>
          <div className={style.description}>Процент угаданных текстов по всем попыткам</div>
          <div className={style.icon}>🤔</div>
          <div className={style.count}>{Math.ceil(textsGuessedPercent)}%</div>
        </div>

        <div className={style.totalBlock}>
          <h3>IQ грелочки</h3>
          <div className={style.description}>Средний процент угадывания для игрока</div>
          <div className={style.icon}>🧠</div>
          <div className={style.count}>{Math.ceil(iqPercent)}%</div>
        </div>

        <div className={style.totalBlock}>
          <h3>Лидер</h3>
          <div className={style.description}>Сколько же баллов у лидера?</div>
          <div className={style.icon}>⭐️</div>
          <div className={style.count}>{topTexts[0].rating}</div>
        </div>

        <div className={style.totalBlock}>
          <h3>Текстов на игрока</h3>
          <div className={style.description}>Куда вы столько пишете? Остановитесь!</div>
          <div className={style.icon}>📄</div>
          <div className={style.count}>{textsPerPlayer.toFixed(2)}</div>
        </div>

        <div className={style.totalBlock}>
          <h3>В среднем номинаций</h3>
          <div className={style.description}>И тут нет пояснения, да что ж такое!</div>
          <div className={style.icon}>🍀</div>
          <div className={style.count}>{averageNominations.toFixed(2)}</div>
        </div>
      </div>
    </>
  )
}
