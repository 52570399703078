import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { Coin, ExtraVoteByType } from '../types/types'

export type Rate = 'good' | 'bad' | 'neutral'

export type ExtraVoteValue = 1 | 2 | 3
export type ExtraVoteType = 'theme' | 'like' | 'readable' | 'style' | 'choose'

export interface ExtraVote {
  type: ExtraVoteType
  value: ExtraVoteValue
  textIndex: number
}

export interface ResultsState {
  texts: {
    title: string
    characters: number
    textsShorterMine: number
    textsLongerMine: number
    rating: number
    myPlace: number
    votesTotal: number
    extraVotesTotal: number
    extraVotes: {
      [key: string]: ExtraVoteByType
    }
    nominations: string[]
    nominationsWon: string[]
    reviews: { text: string }[]
    betsFromMe: {
      amount: number
      coin: Coin
      textTitle: string
      factor: number
    }[]
    betsToMe: {
      beecoin: number
      watermeloncoin: number
    }
    extraPlace: {
      like: number
      theme: number
      readable: number
      style: number
    }
    guesses: {
      correct: number
      total: number
    }
    guessesFrom: {
      correct: number
      total: number
    }
    guessesNamesIdentity: {
      [key: string]: number
    }
  }[]
}

const initialState: ResultsState = {
  texts: [],
}

export const resultsSlice = createSlice({
  name: 'results',
  initialState,
  reducers: {
    setResults: (state, action: PayloadAction<ResultsState>) => {
      state.texts = action.payload.texts
    },
  },
})
