import style from './AdminPlayers.module.scss'
import { TextAdmin } from '../../../../../types/types'
import { AchievementsList } from '../../../user/components/AchievementsList'
import { NominationsList } from '../../../user/components/NominationsList'
import { statusDecoder } from '../../../../../data/statusDecoder'
import React from 'react'
import { AddTextModal } from './AddTextModal'
import { AdminPlayer, ReviewsModalState } from './adminControl.types'
import { RoundTextComponent } from './RoundTextComponent'
import { Bar } from 'react-chartjs-2'
import { compare, getDMY, pluralSets, pluralText } from '../../../../../methods/text.methods'
import { onlyUnique } from '../../TextsAdmin.methods'
import { useDebounce } from '../../../../../hooks/useDebounce'
import { ReviewsModal } from './ReviewsModal'
import { getPlayerChart } from '../../../user/Profile.chart.generator'

interface Props {
  textsAdmin: TextAdmin[]
  players: AdminPlayer[]
}

export const AdminPlayers = (props: Props) => {
  const [players, set_players] = React.useState<AdminPlayer[]>(props.players)
  const [playersFiltered, set_playersFiltered] = React.useState<AdminPlayer[]>([])
  const [addTextModal, set_addTextModal] = React.useState({
    isShow: false,
    userName: '',
  })

  const [reviewModal, set_reviewModal] = React.useState<ReviewsModalState>({
    isShow: false,
    name: '',
    tgName: '',
    reviews: [],
  })

  const [filter, set_filter] = React.useState('')
  const debouncedFilter = useDebounce(filter, 1500)

  React.useEffect(() => {
    set_players(props.players)
    set_playersFiltered(props.players)
  }, [props.players])

  React.useEffect(() => {
    onFilterPlayers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilter])

  function onShowAddTextModal(userName: string) {
    set_addTextModal({
      isShow: true,
      userName,
    })
  }

  function onCloseAddTextModal() {
    set_addTextModal({
      isShow: false,
      userName: '',
    })
  }

  function onShowReviewsModal(player: AdminPlayer) {
    set_reviewModal({
      isShow: true,
      name: player.name,
      tgName: player.tgName,
      reviews: player.reviews,
    })
  }

  function onCloseReviewsModal() {
    set_reviewModal({
      isShow: false,
      name: '',
      tgName: '',
      reviews: [],
    })
  }

  function getTotalScores(player: AdminPlayer) {
    const season1 = player.texts.filter((text) => [1, 2, 3, 4, 5].includes(text.round) && text.extra !== 1).reduce((acc, text) => acc + text.score, 0)
    const season2 = player.texts.filter((text) => [6, 7, 8, 9, 10].includes(text.round) && text.extra !== 1).reduce((acc, text) => acc + text.score, 0)
    const withExtra = player.texts.reduce((acc, text) => acc + text.score, 0)
    return {
      season1,
      season2,
      total: season1 + season2,
      withExtra: withExtra,
    }
  }

  function onFilterPlayers() {
    const textTrimmed = filter.trim()

    const newPlayersFiltered = players.filter((player) => {
      if (!textTrimmed) return true
      const inName = compare(player.name, textTrimmed)
      const inTg = compare(String(player.tgId), textTrimmed)
      const inTexts = player.texts.filter((text) => compare(text.title, textTrimmed))
      return inName || inTg || inTexts.length > 0
    })

    set_playersFiltered(newPlayersFiltered)

    set_filter(filter)
  }

  return (
    <div className={style.control}>
      {players.length > 0 && (
        <>
          <div className={style.controls}>
            <div>Всего {players.length} игроков</div>
            <div>
              <span className={style.greenCircle} /> Онлайн {players.filter((player) => player.tgInvited).length} игроков
            </div>
            <div>
              <span className={style.redCircle} /> Офлайн {players.filter((player) => !player.tgInvited).length} игроков
            </div>
            <div>Поиск</div>
            <input type="text" value={filter} onChange={(event) => set_filter(event.target.value)} />
          </div>
          <div className={style.players}>
            {playersFiltered.map((player, index) => {
              const playerTotalScore = getTotalScores(player)
              const playerChart = getPlayerChart(player.texts)

              const lastActivityFormatter = getDMY(player.lastActivity)

              return (
                <div className={style.player} key={index}>
                  <div className={style.playerInfo}>
                    <div>{player.name}</div>
                    <div>aka {player.tgName}</div>
                    <div>
                      <span>tg: {player.tgId}</span>
                    </div>
                    <div>
                      {player.vkLink ? (
                        <a href={player.vkLink} target="_blank" rel="noreferrer">
                          В контакте
                        </a>
                      ) : (
                        'В вк нету'
                      )}
                    </div>
                    <div>
                      <a href={`/${player.guid}`} target="_blank" rel="noreferrer">
                        Direct control
                      </a>
                    </div>
                    <div>{statusDecoder[player.status]}</div>
                    <div>
                      🐝{player.beecoins} | 🍉{player.watermeloncoins}
                    </div>
                    <div>
                      {player.tgInvited ? <span className={style.greenCircle} /> : ''}
                      {!player.tgInvited ? <span className={style.redCircle} /> : ''} {lastActivityFormatter}
                    </div>

                    <Bar data={playerChart.data} options={playerChart.options} id="chart" width={400} height={400} />

                    <div className={style.totalScore}>1 сезон: {pluralText(playerTotalScore.season1, pluralSets.balls)}</div>
                    <div className={style.totalScore}>2 сезон: {pluralText(playerTotalScore.season2, pluralSets.balls)}</div>
                    <div className={style.totalScore}>Всего: {pluralText(playerTotalScore.total, pluralSets.balls)}</div>
                    <div className={style.totalScore}>Полные: {pluralText(playerTotalScore.withExtra, pluralSets.balls)}</div>
                  </div>

                  <div className={style.playerTexts}>
                    {player.texts.map((text, index2) => {
                      return <RoundTextComponent key={index2} text={text} />
                    })}
                    <button onClick={() => onShowAddTextModal(player.name)}>Добавить текст</button>
                  </div>

                  <div className={style.playerAchievements}>
                    <div>
                      {pluralText(player.achievements.length, pluralSets.achievements)} |{' '}
                      {pluralText(player.achievements.filter(onlyUnique).length, pluralSets.type)}
                    </div>
                    {player.achievements.length > 0 && <AchievementsList achievements={player.achievements} smallMode />}
                  </div>

                  <div className={style.playerAchievements}>
                    {pluralText(player.nominations.length, pluralSets.nominations)}
                    {player.nominations.length > 0 && <NominationsList nominations={player.nominations} smallMode nominationsWon={[]} />}
                  </div>

                  <div className={style.playerButtons}>
                    <button onClick={() => onShowReviewsModal(player)}>Смотреть рецензии</button>
                    {/* <button>Забанить!</button> */}
                    {/* <button>Разбанить</button> */}
                  </div>
                </div>
              )
            })}
          </div>
          {addTextModal.isShow && <AddTextModal userName={addTextModal.userName} callbackClose={onCloseAddTextModal} />}
          {reviewModal.isShow && <ReviewsModal reviewModal={reviewModal} callbackClose={onCloseReviewsModal} />}
        </>
      )}
    </div>
  )
}
