import style from './HallOfFame.module.scss'
import { RoundTextRow } from './HallOfFame.textRow'
import { GrelkaState } from '../../../../store/store'

interface Props {
  library: GrelkaState['library']
}

const ROUNDS_S1 = [1, 2, 3, 4, 5]
const ROUNDS_S2 = [6, 7, 8, 9, 10]

export const Rounds = (props: Props) => {
  const playersSorted = [...props.library.players]
  playersSorted.sort(function (a, b) {
    const summA = props.library.texts.filter((text) => text.user_name === a.name && !text.extra).reduce((acc, text) => acc + text.score, 0)
    const summB = props.library.texts.filter((text) => text.user_name === b.name && !text.extra).reduce((acc, text) => acc + text.score, 0)
    if (summA > summB) return -1
    if (summA < summB) return 1
    return 0
  })

  const playersSortedSeason1 = [...props.library.players]
  playersSortedSeason1.sort(function (a, b) {
    const summA = props.library.texts
      .filter((text) => text.user_name === a.name && ROUNDS_S1.includes(text.round) && !text.extra)
      .reduce((acc, text) => acc + text.score, 0)
    const summB = props.library.texts
      .filter((text) => text.user_name === b.name && ROUNDS_S1.includes(text.round) && !text.extra)
      .reduce((acc, text) => acc + text.score, 0)
    if (summA > summB) return -1
    if (summA < summB) return 1
    return 0
  })

  const playersSortedSeason2 = [...props.library.players]
  playersSortedSeason2.sort(function (a, b) {
    const summA = props.library.texts
      .filter((text) => text.user_name === a.name && ROUNDS_S2.includes(text.round) && !text.extra)
      .reduce((acc, text) => acc + text.score, 0)
    const summB = props.library.texts
      .filter((text) => text.user_name === b.name && ROUNDS_S2.includes(text.round) && !text.extra)
      .reduce((acc, text) => acc + text.score, 0)
    if (summA > summB) return -1
    if (summA < summB) return 1
    return 0
  })

  return (
    <>
      <div className={style.rounds}>
        {playersSorted.map((player, index) => {
          const totalScore = props.library.texts.filter((text) => text.user_name === player.name && !text.extra).reduce((acc, text) => acc + text.score, 0)
          const seasonScore1 = props.library.texts
            .filter((text) => text.user_name === player.name && !text.extra && ROUNDS_S1.includes(text.round))
            .reduce((acc, text) => acc + text.score, 0)
          const seasonScore2 = props.library.texts
            .filter((text) => text.user_name === player.name && !text.extra && ROUNDS_S2.includes(text.round))
            .reduce((acc, text) => acc + text.score, 0)

          const playerTexts1 = props.library.texts.filter((text) => text.user_name === player.name && text.round === 1)
          const playerTexts2 = props.library.texts.filter((text) => text.user_name === player.name && text.round === 2)
          const playerTexts3 = props.library.texts.filter((text) => text.user_name === player.name && text.round === 3)
          const playerTexts4 = props.library.texts.filter((text) => text.user_name === player.name && text.round === 4)
          const playerTexts5 = props.library.texts.filter((text) => text.user_name === player.name && text.round === 5)
          const playerTexts6 = props.library.texts.filter((text) => text.user_name === player.name && text.round === 6)
          const playerTexts7 = props.library.texts.filter((text) => text.user_name === player.name && text.round === 7)

          const seasonPlace1 = playersSortedSeason1.findIndex((_player) => _player.name === player.name)
          const seasonPlace2 = playersSortedSeason2.findIndex((_player) => _player.name === player.name)

          return (
            <div className={style.player} key={index}>
              <h3>{player.name}</h3>
              <div className={style.playerRounds}>
                <RoundTextRow round={1} texts={playerTexts1} isSkipped={false} isPlaying={false} />
                <RoundTextRow round={2} texts={playerTexts2} isSkipped={false} isPlaying={false} />
                <RoundTextRow round={3} texts={playerTexts3} isSkipped={false} isPlaying={false} />
                <RoundTextRow round={4} texts={playerTexts4} isSkipped={false} isPlaying={false} />
                <RoundTextRow round={5} texts={playerTexts5} isSkipped={false} isPlaying={false} />
                <RoundTextRow round={6} texts={playerTexts6} isSkipped={false} isPlaying={false} />
                <RoundTextRow round={7} texts={playerTexts7} isSkipped={false} isPlaying={false} />
              </div>
              ★<div></div>
              <div className={style.playerBalls}>
                <div>
                  <div>1 сезон</div>
                  <div className={style.bigNumber}>{seasonScore1}</div>
                  <div>{seasonPlace1 !== -1 ? seasonPlace1 + 1 : '???'} место</div>
                </div>
                <div>
                  <div>2 сезон</div>
                  <div className={style.bigNumber}>{seasonScore2}</div>
                  <div>{seasonPlace2 !== -1 ? seasonPlace2 + 1 : '???'} место</div>
                </div>
                <div>
                  <div>Всего баллов</div>
                  <div className={style.bigNumber}>{totalScore}</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
