import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RoundText } from '../components/pages/admin/textsAdminTabs/adminControl/adminControl.types'
import { ArchiveReview, HallOfFamePlayer } from '../types/types'
import { GetLibraryResponse } from '../api/user.api'

export interface LibraryState {
  texts: RoundText[]
  players: HallOfFamePlayer[]
  reviews: {
    fromMe: ArchiveReview[]
    toMe: ArchiveReview[]
  }
}

const initialState: LibraryState = {
  texts: [],
  players: [],
  reviews: {
    fromMe: [],
    toMe: [],
  },
}

export const librarySlice = createSlice({
  name: 'library',
  initialState,
  reducers: {
    setLibrary: (state, action: PayloadAction<GetLibraryResponse>) => {
      state.texts = action.payload.library
      state.players = action.payload.players
      state.reviews = action.payload.reviews
    },
  },
})
