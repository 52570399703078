import React from 'react'
import style from './Editor.module.scss'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useOnload } from '../../../hooks/useOnload'
import { createTextApi, getMyTextsApi } from '../../../api/editor.api'
import { EditorText } from '../../../types/editor.types'
import { pluralSets, pluralText } from '../../../methods/text.methods'
import { getProfileMethod } from '../../../methods/app.methods'
import { ActionType, Text } from '../../../types/types'
import { GrelkaState } from '../../../store/store'
import { connect } from 'react-redux'
import cn from 'classnames'

interface Props {
  profile: GrelkaState['profile']
}

export const SelectTextComponent = (props: Props) => {
  let { guid = '' } = useParams()
  const navigate = useNavigate()
  const [texts, set_texts] = React.useState<EditorText[]>([])
  const [textsGoogle, set_textsGoogle] = React.useState<Text[]>([])

  React.useEffect(() => {
    // on load text
  }, [guid])

  useOnload(() => {
    getProfileMethod(guid)
    getMyTextsApi(guid).then((response) => {
      set_texts(response.data.editor)
      set_textsGoogle(response.data.google)
    })
  })

  function createNewText() {
    createTextApi(guid).then((response) => {
      const textGuid = response.data
      navigate(`/editor/${guid}/${textGuid}`)
    })
  }

  function goBackToProfile() {
    navigate(`/${guid}`)
  }

  const AWAIT_START = [ActionType.AwaitText, ActionType.AwaitStartVoting].includes(props.profile.status)
  const IN_GAME = ![ActionType.Greetings, ActionType.AwaitName, ActionType.AwaitText, ActionType.AwaitStartVoting, ActionType.GameEnded].includes(
    props.profile.status
  )

  return (
    <>
      <div className={style.page}>
        <div className={style.selectText}>
          {IN_GAME && (
            <>
              <h2>Игра уже началась</h2>
              <h2>Тексты писать сейчас нельзя!</h2>
              <button onClick={goBackToProfile}>Вернуться в профиль</button>
            </>
          )}
          {AWAIT_START && (
            <>
              <h2>Твои тексты</h2>
              {texts.length === 0 && textsGoogle.length === 0 && <div>У тебя пока что нет текстов :(</div>}
              {(texts.length > 0 || textsGoogle.length > 0) && (
                <>
                  <div className={style.texts}>
                    {texts.map((text, index) => {
                      return (
                        <Link key={index} to={`/editor/${guid}/${text.text_guid}`}>
                          <div className={style.flex}>
                            <div className={style.title}>{text.title}</div>
                            <div className={style.characters}>{pluralText(text.characters, pluralSets.characters)}</div>
                            <div className={style.button}>редактировать</div>
                          </div>
                        </Link>
                      )
                    })}
                    {textsGoogle.map((gText, index) => {
                      return (
                        <Link key={index} to={gText.link} target="_blank">
                          <div className={cn(style.flex, style.gText)}>
                            <div className={style.title}>{gText.title ? gText.title : 'Название проверяется'}</div>
                            <div className={style.characters}>{gText.characters ? pluralText(gText.characters, pluralSets.characters) : 'Знаки считаются'}</div>
                            {!gText.title && !gText.characters && <div className={style.characters}>Никому не двигаться!</div>}
                            <div className={style.button}>открыть в гуглдоках</div>
                          </div>
                        </Link>
                      )
                    })}
                  </div>
                </>
              )}
              <button onClick={createNewText}>Начать новый текст!</button>
            </>
          )}
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state: GrelkaState) => {
  return {
    profile: state.profile,
  }
}

export const SelectText = connect(mapStateToProps, {})(SelectTextComponent)
