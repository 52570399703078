import React from 'react'
import style from './AdminService.module.scss'
import { addCoinsApi, banPlayersApi, generateBalancesApi, getCoinsApi, shuffleTextsApi, transferEditorTextsApi } from '../../../../../api/adminControl.api'
import { useParams } from 'react-router-dom'

interface Props {}

export const AdminService = (props: Props) => {
  let { guid = '' } = useParams()

  const [transfered, set_transfered] = React.useState<null | number>(null)
  const [shuffled, set_shuffled] = React.useState<boolean>(false)
  const [betsProceed, set_betsProceed] = React.useState<number | null>(null)
  const [balancesCreated, set_balancesCreated] = React.useState<number | null>(null)
  const [coinsAdded, set_coinsAdded] = React.useState<number | null>(null)
  const [bannedPlayers, set_bannedPlayers] = React.useState<number | null>(null)

  function transferEditorTexts() {
    set_transfered(null)
    transferEditorTextsApi(guid).then((response) => {
      set_transfered(response.data.transfered)
    })
  }

  function shuffleTexts() {
    set_shuffled(false)
    shuffleTextsApi(guid).then((response) => {
      set_shuffled(response.data)
    })
  }

  function getCoins() {
    set_betsProceed(null)
    getCoinsApi(guid).then((response) => {
      set_betsProceed(response.data)
    })
  }

  function generateBalances() {
    set_balancesCreated(null)
    generateBalancesApi(guid).then((response) => {
      set_balancesCreated(response.data)
    })
  }

  function addCoins() {
    set_coinsAdded(null)
    addCoinsApi(guid).then((response) => {
      set_coinsAdded(response.data)
    })
  }

  function banPlayers() {
    set_bannedPlayers(null)
    banPlayersApi(guid).then((response) => {
      set_bannedPlayers(response.data)
    })
  }

  return (
    <>
      <div className={style.adminService}>
        <h3>Перед игрой</h3>

        <div className={style.controlBlock}>
          <h3>Трансфер текстов</h3>
          <div>Перенос текстов из таблицы editor_texts в texts с автогенерацией твинк-юзеров (легаси), текстам редактора будет проставлено transfered = 1</div>
          <button onClick={transferEditorTexts}>Выполнить</button>
          {transfered != null && <div className={style.green}>Перенесено текстов: {String(transfered)}</div>}
        </div>

        <div className={style.controlBlock}>
          <h3>Машина хаоса</h3>
          <div>Перемешивание текстов. ВНИМАНИЕ: тексты не должны иметь одинаковые линки, потому что это примари поле</div>
          <button onClick={shuffleTexts}>Выполнить</button>
          {shuffled && <div className={style.green}>Перемешано</div>}
        </div>

        <div className={style.controlBlock}>
          <h3>Снабжение</h3>
          <div>Добавление всем активным игрокам 1 коина в таблицу balances</div>
          <button onClick={addCoins}>Выполнить</button>
          {coinsAdded != null && <div className={style.green}>Обогащённых игроков: {coinsAdded}</div>}
        </div>

        <div className={style.controlBlock}>
          <h3>Сгенерировать балансы</h3>
          <div>Генерация balances на основе балансов из players. НЕ добавляет 1 к балансам</div>
          <button onClick={generateBalances}>Выполнить</button>
          {balancesCreated != null && <div className={style.green}>Создано балансов: {balancesCreated}</div>}
        </div>

        <h3>После игры</h3>

        <div className={style.controlBlock}>
          <h3>Завеса тайны</h3>
          <div>Проставить всем users, кроме админа, статус banned</div>
          <button onClick={banPlayers}>Выполнить</button>
          {bannedPlayers != null && <div className={style.green}>Забанено: {bannedPlayers}</div>}
        </div>

        <div className={style.controlBlock}>
          <h3>Получить коины со ставок</h3>
          <div>Обработка таблицы bets и результата вычисления ставок, добавление коинов для players, затирает таблицу bets</div>
          <button onClick={getCoins}>Выполнить</button>
          {betsProceed != null && <div className={style.green}>Обработано ставок: {betsProceed}</div>}
        </div>

        <div className={style.controlBlock}>
          <h3>Архивация текстов</h3>
          <div>Перенос текстов в таблицу editor_texts_archive (она ещё не создана)</div>
          <button disabled>Выполнить</button> <span className={style.red}>Не реализовано</span>
        </div>

        <div className={style.controlBlock}>
          <h3>Архивация рецензий</h3>
          <div>Пренос рецензий в reviews_archive (работоспособность не проверена)</div>
          <button disabled>Выполнить</button> <span className={style.red}>Не реализовано</span>
        </div>
      </div>
    </>
  )
}
