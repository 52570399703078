import React from 'react'
import { Text } from '../../types/types'
import { ExtraVote, ExtraVoteType, ExtraVoteValue, profileSlice } from '../../store/profile.slice'
import { postExtraVote } from '../../api/user.api'
import { useParams } from 'react-router-dom'
import { grelkaStore } from '../../store/store'
import { AudioManager } from '../pages/user/components/AudioManager'
import style from './TextExtraVote.module.scss'

interface Props {
  text: Text
  index: number
  userExtraVotes: ExtraVote[]
}

interface Question {
  title: string
  description: string
  type: ExtraVoteType
  answers: [string, string, string]
}

const questions: Question[] = [
  {
    title: 'Мудрость',
    description: 'Раскрыта тема?',
    type: 'theme',
    answers: ['Не очень', 'Нормально', 'Раскрыта!'],
  },
  {
    title: 'Радость',
    description: 'Понравился текст?',
    type: 'like',
    answers: ['Не очень', 'Нормально', 'Понравился!'],
  },
  {
    title: 'Лёгкость',
    description: 'Легко читается?',
    type: 'readable',
    answers: ['Не очень', 'Нормально', 'Легко!'],
  },
  {
    title: 'Красота',
    description: 'Понравился стиль?',
    type: 'style',
    answers: ['Не очень', 'Нормально', 'Понравился!'],
  },
]

export const TextExtraVote = (props: Props) => {
  let { guid = '' } = useParams()
  const [selectedTypes, set_selectedTypes] = React.useState<ExtraVoteType[]>([])
  const [audioTick, set_audioTick] = React.useState(0)

  function sendExtraVote(type: ExtraVoteType, value: ExtraVoteValue) {
    set_selectedTypes([...selectedTypes, type])
    postExtraVote({
      guid,
      textIndex: props.index,
      type,
      value,
    }).then((response) => {
      grelkaStore.dispatch(profileSlice.actions.addExtraVote(response.data))
      set_audioTick(audioTick + 1)
    })
  }

  return (
    <div className={style.extraVoteBlocks}>
      <AudioManager tick={audioTick} type="chpok" />
      {questions.map((question, index) => {
        const hasSelectedNow = selectedTypes.includes(question.type)
        const hasSelectedBefore = props.userExtraVotes.filter((extraVote) => extraVote.textIndex === props.index && extraVote.type === question.type).length > 0
        if (hasSelectedNow) return null
        if (hasSelectedBefore) return null
        return (
          <div className={style.extraVoteBlock} key={index}>
            <div className={style.title}>{question.title}</div>
            <div className={style.description}>{question.description}</div>
            <div className={style.buttons}>
              <button className={style.red} onClick={() => sendExtraVote(question.type, 1)}>
                {question.answers[0]}
              </button>
              <button className={style.yellow} onClick={() => sendExtraVote(question.type, 2)}>
                {question.answers[1]}
              </button>
              <button className={style.green} onClick={() => sendExtraVote(question.type, 3)}>
                {question.answers[2]}
              </button>
            </div>
          </div>
        )
      })}
    </div>
  )
}
