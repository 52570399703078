import { pluralSets, pluralText } from '../../../../../methods/text.methods'
import style from './ReviewsModal.module.scss'
import { ReviewsModalState } from './adminControl.types'

interface Props {
  reviewModal: ReviewsModalState
  callbackClose: () => void
}

export const ReviewsModal = (props: Props) => {
  function goBack() {
    props.callbackClose()
  }

  const totalLength = props.reviewModal.reviews.reduce((acc, review) => acc + review.text.length, 0)

  return (
    <div className={style.modal}>
      <div className={style.reviewsModal}>
        <div className={style.header}>
          <h2>Все рецензии пользователя</h2>
          <div>
            {props.reviewModal.name} aka {props.reviewModal.tgName}
          </div>
        </div>

        {props.reviewModal.reviews.length > 0 && (
          <div>
            {pluralText(props.reviewModal.reviews.length, pluralSets.reviews)} | {pluralText(totalLength, pluralSets.characters)}
          </div>
        )}

        <div className={style.reviews}>
          {props.reviewModal.reviews.map((review, index) => {
            const rows = review.text.split('\n')
            return (
              <div className={style.review} key={index}>
                <div>
                  {review.id}: Для текста «{review.title}»
                </div>
                {rows.map((row, index2) => {
                  return (
                    <div className={style.reviewRow} key={index2}>
                      {row}
                    </div>
                  )
                })}
              </div>
            )
          })}
        </div>

        {props.reviewModal.reviews.length === 0 && <div className={style.noReviews}>А рецензий-то и нет :(</div>}

        <button onClick={goBack}>Назад</button>
      </div>
    </div>
  )
}
