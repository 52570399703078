import style from './Reviews.module.scss'
import { GrelkaState } from './../../../store/store'
import { Tabs } from '../../ui/Tabs'
import { Tab } from '../../ui/Tab'
import { ReviewComponent } from './Review'

interface Props {
  library: GrelkaState['library']
}

export const Reviews = (props: Props) => {
  const tabs = [
    { name: 'toMe', title: 'Для меня' },
    { name: 'fromMe', title: 'От меня' },
  ]
  const tabs2 = [
    { name: 'round6', title: '6 Грелочка' },
    { name: 'round7', title: '7 Грелочка' },
  ]

  const reviews = {
    toMe: {
      round6: props.library.reviews.toMe.filter((review) => review.round === 6),
      round7: props.library.reviews.toMe.filter((review) => review.round === 7),
    },
    fromMe: {
      round6: props.library.reviews.fromMe.filter((review) => review.round === 6),
      round7: props.library.reviews.fromMe.filter((review) => review.round === 7),
    },
  }

  return (
    <div className={style.reviews}>
      <Tabs tabs={tabs}>
        <Tab name="toMe">
          <Tabs tabs={tabs2}>
            <Tab name="round6">
              <div className={style.reviewsList}>
                {reviews.toMe.round6.map((review, index) => {
                  return <ReviewComponent review={review} key={index} isMine={false} />
                })}
              </div>
            </Tab>
            <Tab name="round7">
              <div className={style.reviewsList}>
                {reviews.toMe.round7.map((review, index) => {
                  return <ReviewComponent review={review} key={index} isMine={false} />
                })}
              </div>
            </Tab>
          </Tabs>
        </Tab>
        <Tab name="fromMe">
          <Tabs tabs={tabs2}>
            <Tab name="round6">
              <div className={style.reviewsList}>
                {reviews.fromMe.round6.map((review, index) => {
                  return <ReviewComponent review={review} key={index} isMine={true} />
                })}
              </div>
            </Tab>
            <Tab name="round7">
              <div className={style.reviewsList}>
                {reviews.fromMe.round7.map((review, index) => {
                  return <ReviewComponent review={review} key={index} isMine={true} />
                })}
              </div>
            </Tab>
          </Tabs>
        </Tab>
      </Tabs>
    </div>
  )
}
