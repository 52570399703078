import style from './PlayersInfo.module.scss'
import { ActionType, TextAdmin } from '../../../../../types/types'
import { AchievementsList } from '../../../user/components/AchievementsList'
import { NominationsList } from '../../../user/components/NominationsList'
import { statusDecoder } from '../../../../../data/statusDecoder'
import React from 'react'
import { AddTextModal } from './AddTextModal'
import { AdminPlayer, ReviewsModalState } from './adminControl.types'
import { RoundTextComponent } from './RoundTextComponent'
import { Bar } from 'react-chartjs-2'
import { compare, getDMY, pluralSets, pluralText } from '../../../../../methods/text.methods'
import { onlyUnique } from '../../TextsAdmin.methods'
import { useDebounce } from '../../../../../hooks/useDebounce'
import { ReviewsModal } from './ReviewsModal'
import { getPlayerChart } from '../../../user/Profile.chart.generator'
import cn from 'classnames'

interface Props {
  players: AdminPlayer[]
}

export const PlayersInfo = (props: Props) => {
  const [players, set_players] = React.useState<AdminPlayer[]>(props.players)

  React.useEffect(() => {
    set_players(props.players)
  }, [props.players])

  const activePlayers = players.filter((player) => player.currentGame.activeTexts.google.length > 0 || player.currentGame.activeTexts.editor.length > 0)
  const skipPlayers = players.filter((player) => player.currentGame.activeTexts.google.length === 0 && player.currentGame.activeTexts.editor.length === 0)
  const totalTexts = players.reduce((acc, player) => acc + player.currentGame.activeTexts.editor.length + player.currentGame.activeTexts.google.length, 0)
  const totalGoogleTexts = players.reduce((acc, player) => acc + player.currentGame.activeTexts.google.length, 0)
  const totalEditorTexts = players.reduce((acc, player) => acc + player.currentGame.activeTexts.editor.length, 0)
  const averageTexts = (totalTexts / activePlayers.length).toFixed(2)

  return (
    <div className={style.playersInfo}>
      {players.length > 0 && (
        <>
          <div>Активных игроков: {activePlayers.length}</div>
          <div>Всего текстов: {totalTexts}</div>
          <div>
            Через гугл: {totalGoogleTexts} ({((totalGoogleTexts / totalTexts) * 100).toFixed(0)}%)
          </div>
          <div>
            Через редактор: {totalEditorTexts} ({((totalEditorTexts / totalTexts) * 100).toFixed(0)}%)
          </div>
          <div>В среднем текстов: {averageTexts}</div>

          <hr />

          <h3>Играют</h3>

          <table>
            <thead>
              <th>Имя</th>
              <th>TG id</th>
              <th>TG имя</th>
              <th>
                <table className={style.miniTable}>
                  <td>глс</td>
                  <td>мрлк</td>
                  <td>ном</td>
                  <td>угд</td>
                  <td>ствк</td>
                  <td>рц</td>
                </table>
              </th>
              <th>Тексты</th>
              <th>Символы</th>
            </thead>
            <tbody>
              {activePlayers.map((player, index) => {
                const activeTexts = player.currentGame.activeTexts
                const characters = [...activeTexts.google, ...activeTexts.editor].reduce((acc, text) => acc + text.characters, 0)
                const charactersJoin = [...activeTexts.google, ...activeTexts.editor].map((text) => text.characters).join('+')

                const textsTotal = activeTexts.google.length + activeTexts.editor.length

                return (
                  <tr key={index}>
                    <td>{player.name}</td>
                    <td>{player.tgId}</td>
                    <td>{player.tgName}</td>
                    <td>
                      <table className={style.miniTable}>
                        <td
                          className={cn({
                            [style.green]: player.currentGame.votes > 0,
                            [style.red]: player.currentGame.votes === 0,
                          })}
                        >
                          {player.currentGame.votes}
                        </td>
                        <td
                          className={cn({
                            [style.green]: player.currentGame.extraVotes > 0,
                            [style.red]: player.currentGame.extraVotes === 0,
                          })}
                        >
                          {player.currentGame.extraVotes}
                        </td>
                        <td
                          className={cn({
                            [style.green]: player.currentGame.nominations > 0,
                            [style.red]: player.currentGame.nominations === 0,
                          })}
                        >
                          {player.currentGame.nominations}
                        </td>
                        <td
                          className={cn({
                            [style.green]: player.currentGame.guesses > 0,
                            [style.red]: player.currentGame.guesses === 0,
                          })}
                        >
                          {player.currentGame.guesses}
                        </td>
                        <td
                          className={cn({
                            [style.green]: player.currentGame.bets > 0,
                            [style.red]: player.currentGame.bets === 0,
                          })}
                        >
                          {player.currentGame.bets}
                        </td>
                        <td
                          className={cn({
                            [style.green]: player.currentGame.reviews > 0,
                            [style.red]: player.currentGame.reviews === 0,
                          })}
                        >
                          {player.currentGame.reviews}
                        </td>
                      </table>
                    </td>
                    <td>
                      {activeTexts.google.length}+{activeTexts.editor.length} = {textsTotal}
                    </td>
                    <td>
                      {charactersJoin} = {characters}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>

          <hr />

          <h3>Не играют</h3>

          <table>
            <thead>
              <th>Имя</th>
              <th>TG id</th>
            </thead>
            <tbody>
              {skipPlayers.map((player, index) => {
                return (
                  <tr key={index} className={style.red}>
                    <td>{player.name}</td>
                    <td>{player.tgId}</td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </>
      )}
    </div>
  )
}
