import style from './AdminAchievements.module.scss'
import { TextAdmin } from '../../../../types/types'
import {
  mostActiveUsersMethod,
  lessGuessedMethod,
  mostReviewReadersMethod,
  mostReviewWritersMethod,
  mostGuessedMethod,
  achievementKeepersMethod,
  hasManyNominationsMethod,
  goodSolyankaMethod,
  topTextsMethod,
  longestTextsMethod,
  shortestTextsMethod,
  barelyDeadMethod,
  most_like_method,
  most_theme_method,
  most_readable_method,
  most_style_method,
  mostBettedMethod,
  badSolyankaMethod,
  metaGrelo4kaMethod,
} from '../TextsAdmin.methods'
import { fameUsers, SKIP_TEXT } from '../../../../data/users'
import { NominationBlock } from './../../user/components/NominationBlock'
import { achievementsList, NominationItem, nominationsList } from '../../../../data/nominations'
import { BetsState } from '../../../../store/bets.slice'
import { NominationType } from '../../../../store/profile.slice'

interface AchievementResultBlockProps {
  fabricProduct: FabricProduct
}
const AchievementResultBlock = (props: AchievementResultBlockProps) => {
  let icon = achievementsList.find((item) => item.type === props.fabricProduct.iconType) as NominationItem
  if (!icon) {
    icon = nominationsList.find((item) => item.type === props.fabricProduct.iconType) as NominationItem
  }

  // @ts-ignore
  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index
  }

  return (
    <div className={style.achievemntBlock}>
      <div className={style.achievemntBlockIcon}>{icon ? <NominationBlock count={1} smallMode item={icon} /> : 'hz'}</div>
      <div>
        <div className={style.achievemntBlockTitle}>{icon ? icon.title : '???'}</div>
        {props.fabricProduct && (
          <div className={style.achievemntBlockUsers}>
            <div>
              {props.fabricProduct.users

                .map((user) => user.realUser)
                .filter(onlyUnique)
                .join(', ')}{' '}
            </div>
            {props.fabricProduct.users.length !== 0 && props.fabricProduct.value !== undefined && (
              <div>
                {props.fabricProduct.value} {props.fabricProduct.valueText}
              </div>
            )}
            <div>{props.fabricProduct.users.length === 0 && 'Никто :('}</div>
          </div>
        )}
      </div>
    </div>
  )
}

export interface FabricProduct {
  users: TextAdmin[]
  value?: number
  valueText?: string
  iconType: NominationType
}

interface Fabric {
  [key: string]: FabricProduct
}

interface Props {
  textsAdmin: TextAdmin[]
  bets: BetsState
}

export const AdminAchievements = (props: Props) => {
  function packWithZero(users: TextAdmin[], iconType: NominationType): FabricProduct {
    return {
      users: [...users],
      iconType,
    }
  }

  function findNominantionWinner(type: NominationType): FabricProduct {
    const winner = props.textsAdmin.find((text) => text.nominationsWon.includes(type))
    const value = winner ? winner.nominations.filter((nom) => nom === type).length : 0
    return {
      value: value,
      valueText: 'номинаций',
      users: winner ? [winner] : [],
      iconType: type,
    }
  }

  const topThreeTexts = topTextsMethod(props.textsAdmin).slice(0, 3)

  const betWinners = props.bets.results
    .filter((bet) => bet.factor !== 0)
    .map((bet) => {
      const user = props.textsAdmin.find((text) => text.realUser === bet.userName)
      return user || null
    })
    .filter((n) => n) as TextAdmin[]

  const fabric: Fabric = {
    winner1: packWithZero([topThreeTexts[0]], '1st'),
    winner2: packWithZero([topThreeTexts[1]], '2nd'),
    winner3: packWithZero([topThreeTexts[2]], '3rd'),
    graveyard: packWithZero(
      props.textsAdmin.filter((text) => text.rating === 0),
      'dead'
    ),
    hasTwinks: packWithZero(
      props.textsAdmin.filter((text) => text.hasTwinks),
      'graphoman'
    ),
    playAskarons: packWithZero(
      props.textsAdmin.filter((text) => text.clan === 1),
      'clan_askarons'
    ),
    playPushki: packWithZero(
      props.textsAdmin.filter((text) => text.clan === 2),
      'clan_pushki'
    ),
    playDraniki: packWithZero(
      props.textsAdmin.filter((text) => text.clan === 3),
      'clan_draniki'
    ),
    playBeses: packWithZero(
      props.textsAdmin.filter((text) => text.clan === 4),
      'clan_beses'
    ),
    betWinners: packWithZero(betWinners, 'betwin'),
    longestTexts: longestTextsMethod(props.textsAdmin),
    shortestTexts: shortestTextsMethod(props.textsAdmin),
    mostActiveUsers: mostActiveUsersMethod(props.textsAdmin),
    mostReviewWriters: mostReviewWritersMethod(props.textsAdmin),
    mostReviewReaders: mostReviewReadersMethod(props.textsAdmin),
    lessGuessed: lessGuessedMethod(props.textsAdmin),
    moreGuessed: mostGuessedMethod(props.textsAdmin),
    hasNominations_10: hasManyNominationsMethod(props.textsAdmin, 10, 'nominant10'),
    hasNominations_20: hasManyNominationsMethod(props.textsAdmin, 20, 'nominant20'),
    // hasNominations_30: hasManyNominationsMethod(props.textsAdmin, 30),
    mostNominated: goodSolyankaMethod(props.textsAdmin),
    mostNominatedBad: badSolyankaMethod(props.textsAdmin),
    barelyDead: barelyDeadMethod(props.textsAdmin),
    mostBetted: mostBettedMethod(props.textsAdmin),
    top_like: most_like_method(props.textsAdmin),
    top_theme: most_theme_method(props.textsAdmin),
    top_readable: most_readable_method(props.textsAdmin),
    top_style: most_style_method(props.textsAdmin),
    noNominations: packWithZero(
      props.textsAdmin.filter((text) => text.nominations.length === 0),
      'no_nominations'
    ),
    metaGrelo4ka: metaGrelo4kaMethod(props.textsAdmin),
    standup: findNominantionWinner('standup'),
    drama: findNominantionWinner('drama'),
    nabokov: findNominantionWinner('nabokov'),
    mimimi: findNominantionWinner('mimimi'),
    chef: findNominantionWinner('chef'),
    ogo: findNominantionWinner('ogo'),
    not_ready: findNominantionWinner('not_ready'),
    literally: findNominantionWinner('literally'),
    interesting: findNominantionWinner('interesting'),
    normie: findNominantionWinner('normie'),
    light: findNominantionWinner('light'),
    dark: findNominantionWinner('dark'),
    scary: findNominantionWinner('scary'),
    cosy: findNominantionWinner('cosy'),
    hard: findNominantionWinner('hard'),
    lore: findNominantionWinner('lore'),
    educational: findNominantionWinner('educational'),
    massacre: findNominantionWinner('massacre'),
  }

  const newAchievementsTotal = Object.values(fabric).reduce((acc, item) => acc + item.users.length, 0)
  const achievementKeeper = achievementKeepersMethod()

  // Кто из известных не сдал
  const usersPlayedNames = props.textsAdmin.map((text) => text.realUser)
  const fameUsersNames = fameUsers.map((user) => user.name)
  const notPlayed = fameUsersNames.filter((name) => !usersPlayedNames.includes(name))

  return (
    <div className={style.achievemnts}>
      <h2>Фабрика ачивок</h2>
      <div className={style.achievemntsList}>
        <AchievementResultBlock fabricProduct={fabric.winner1} />
        <AchievementResultBlock fabricProduct={fabric.winner2} />
        <AchievementResultBlock fabricProduct={fabric.winner3} />
        <AchievementResultBlock fabricProduct={fabric.graveyard} />
        <AchievementResultBlock fabricProduct={fabric.barelyDead} />
        <AchievementResultBlock fabricProduct={fabric.longestTexts} />
        <AchievementResultBlock fabricProduct={fabric.shortestTexts} />
        <AchievementResultBlock fabricProduct={fabric.mostActiveUsers} />
        <AchievementResultBlock fabricProduct={fabric.mostReviewWriters} />
        <AchievementResultBlock fabricProduct={fabric.mostReviewReaders} />
        <AchievementResultBlock fabricProduct={fabric.metaGrelo4ka} />
        <AchievementResultBlock fabricProduct={fabric.lessGuessed} />
        <AchievementResultBlock fabricProduct={fabric.moreGuessed} />
        <AchievementResultBlock fabricProduct={fabric.hasNominations_10} />
        <AchievementResultBlock fabricProduct={fabric.hasNominations_20} />
        {/* <AchievementResultBlock  methodResult={hasNominations_30} /> */}
        <AchievementResultBlock fabricProduct={fabric.mostNominated} />
        <AchievementResultBlock fabricProduct={fabric.mostNominatedBad} />
        <AchievementResultBlock fabricProduct={fabric.noNominations} />
        <AchievementResultBlock fabricProduct={fabric.hasTwinks} />
        <AchievementResultBlock fabricProduct={fabric.playAskarons} />
        <AchievementResultBlock fabricProduct={fabric.playPushki} />
        <AchievementResultBlock fabricProduct={fabric.playDraniki} />
        <AchievementResultBlock fabricProduct={fabric.playBeses} />
        <AchievementResultBlock fabricProduct={fabric.betWinners} />
        <AchievementResultBlock fabricProduct={fabric.mostBetted} />

        <AchievementResultBlock fabricProduct={fabric.top_like} />
        <AchievementResultBlock fabricProduct={fabric.top_theme} />
        <AchievementResultBlock fabricProduct={fabric.top_readable} />
        <AchievementResultBlock fabricProduct={fabric.top_style} />

        <AchievementResultBlock fabricProduct={fabric.standup} />
        <AchievementResultBlock fabricProduct={fabric.drama} />
        <AchievementResultBlock fabricProduct={fabric.nabokov} />
        <AchievementResultBlock fabricProduct={fabric.mimimi} />
        <AchievementResultBlock fabricProduct={fabric.chef} />
        <AchievementResultBlock fabricProduct={fabric.ogo} />
        <AchievementResultBlock fabricProduct={fabric.not_ready} />
        <AchievementResultBlock fabricProduct={fabric.literally} />
        <AchievementResultBlock fabricProduct={fabric.interesting} />
        <AchievementResultBlock fabricProduct={fabric.normie} />
        <AchievementResultBlock fabricProduct={fabric.light} />
        <AchievementResultBlock fabricProduct={fabric.dark} />
        <AchievementResultBlock fabricProduct={fabric.scary} />
        <AchievementResultBlock fabricProduct={fabric.cosy} />
        <AchievementResultBlock fabricProduct={fabric.hard} />
        <AchievementResultBlock fabricProduct={fabric.lore} />
        <AchievementResultBlock fabricProduct={fabric.educational} />
        <AchievementResultBlock fabricProduct={fabric.massacre} />
      </div>
      <hr />
      <div>Новых ачивок: {newAchievementsTotal}</div>
      <div>
        Манчкин: {achievementKeeper.users.map((user) => user.name).join(', ')} ({achievementKeeper.value})
      </div>
      <hr />
      <div>Не играют: {notPlayed.map((name) => name).join(', ')}</div>
      <hr />
      <div className={style.texts}>
        {fameUsers.map((user, index) => {
          // const inputValue = 'testtesttesttesttesttesttesttesttesttesttesttesttesttesttesttesttesttesttest'
          const accumulator: string[] = []
          const accumulatorSQL: string[] = []

          Object.entries(fabric).forEach(([fabricKey, fabricItem]) => {
            if (fabricItem.users.filter((text) => text.realUser === user.name).length > 0) {
              const nominationTypes = nominationsList.map((nom) => nom.type)
              const achievementTypes = achievementsList.map((ach) => ach.type)

              // let sql = ''
              if (nominationTypes.includes(fabricItem.iconType)) {
                accumulatorSQL.push(`INSERT INTO player_nominations (user_name, nomination_type) VALUES ('${user.name}', '${fabricItem.iconType}');`)
              }
              if (achievementTypes.includes(fabricItem.iconType)) {
                accumulatorSQL.push(`INSERT INTO player_achievements (user_name, achievement_type) VALUES ('${user.name}', '${fabricItem.iconType}');`)
              }

              // fameUsers
              //   .filter((user) => user.round6[0] !== SKIP_TEXT)
              //   .map((user) => {
              //     accumulatorSQL.push(`INSERT INTO player_achievements (user_name, achievement_type) VALUES ('${user.name}', '2season');`)
              //   })

              accumulator.push(fabricItem.iconType)
            }
          }, [] as NominationType[])

          if (!user.achievements.includes('2season')) {
            accumulator.push('2season')
          }
          if (accumulator.includes('reviewer') && accumulator.includes('reviewed')) {
            accumulator.push('reviews_both')
          }
          if (
            (user.achievements.includes('like') || accumulator.includes('like')) &&
            (user.achievements.includes('style') || accumulator.includes('style')) &&
            (user.achievements.includes('theme') || accumulator.includes('theme')) &&
            (user.achievements.includes('readable') || accumulator.includes('readable'))
          ) {
            accumulator.push('extra_master')
          }

          const inputValue = accumulator.map((text) => `"${text}"`).join(', ')

          return (
            <div className={style.userBlock} key={index}>
              <div className={style.userName}>{user.name}</div>
              <div className={style.userCount}>{accumulator.length}</div>
              <div className={style.userCode}>
                <input type="text" value={inputValue} />
                <textarea style={{ height: 400, width: '100%' }}>{accumulatorSQL.join('\n')}</textarea>
              </div>
            </div>
          )
        })}
      </div>

      <hr />

      <div className={style.info}>
        <h3>Какие ачивки проверить?</h3>
        <p>Проверить, что "несгибаемые" и "драконы" продолжили играть, проверить "прогульщиков", "доминаторов" и "на марс", посчитать "Директора кладбища"</p>
        <h3>Раздать</h3>
        <p>Раздать "иллюстратора", "иной", "командный игрок", "НЛО"</p>
      </div>
    </div>
  )
}
