import style from './AdminTextGuessers.module.scss'
import { TextAdmin } from '../../../../types/types'
import { AdminPlayer } from './adminControl/adminControl.types'

interface Props {
  players: AdminPlayer[]
}

export const AdminTextGuessers = (props: Props) => {
  const correctGueesses: { [key: string]: number } = {}
  props.players
    .filter((player) => player.tgInvited)
    .forEach((player) => {
      const name = player.name
      if (correctGueesses[name]) {
        correctGueesses[name] += player.guessesUserCorrect
      } else {
        correctGueesses[name] = player.guessesUserCorrect
      }
    })
  const correctGueessesList = Object.entries(correctGueesses).map(([name, count]) => {
    return {
      name,
      count,
    }
  })
  correctGueessesList.sort(function (a, b) {
    if (a.count < b.count) return 1
    if (a.count > b.count) return -1
    return 0
  })

  const wrongGueesses: { [key: string]: number } = {}
  props.players
    .filter((player) => player.tgInvited)
    .forEach((player) => {
      const name = player.name
      if (wrongGueesses[name]) {
        wrongGueesses[name] += player.guessesUserWrong
      } else {
        wrongGueesses[name] = player.guessesUserWrong
      }
    })
  const wrongGueessesList = Object.entries(wrongGueesses).map(([name, count]) => {
    return {
      name,
      count,
    }
  })
  wrongGueessesList.sort(function (a, b) {
    if (a.count < b.count) return 1
    if (a.count > b.count) return -1
    return 0
  })

  return (
    <div className={style.guessers}>
      <h2>Угадыватели</h2>

      <div className={style.flex}>
        <div className={style.namesTotal}>
          <h3>Топ угатыватели</h3>
          <table>
            {correctGueessesList.map((user) => {
              return (
                <tr>
                  <td>{user.name}</td>
                  <td>{user.count}</td>
                </tr>
              )
            })}
          </table>
        </div>
        <div className={style.namesTotal}>
          <h3>Топ не угатыватели</h3>
          <table>
            {wrongGueessesList.map((user) => {
              return (
                <tr>
                  <td>{user.name}</td>
                  <td>{user.count}</td>
                </tr>
              )
            })}
          </table>
        </div>
      </div>
    </div>
  )
}
