import React from 'react'
import { ProfileState } from '../../store/profile.slice'
import { TextBlock } from './TextBlock'
import { Text } from '../../types/types'
import { ReviewModal } from './ReviewModal'

interface Props {
  texts: Text[]
  profile: ProfileState
}

export const Texts = (props: Props) => {
  const [reviewTextIndex, set_reviewTextIndex] = React.useState(-1)

  function setReviewIndex(textIndex: number) {
    set_reviewTextIndex(textIndex)
  }

  return (
    <>
      {reviewTextIndex !== -1 && <ReviewModal textIndex={reviewTextIndex} texts={props.texts} onChangeIndexCallback={setReviewIndex} />}
      <div className="texts">
        {props.texts.map((text, index) => {
          const badges = props.profile.extraVotes.filter((vote) => vote.textIndex === index + 1)

          // TODO: нереализовано, потому что не будут учитываться все твинки
          // let shiftedIndex = 0
          // const reFindTextIndex = props.texts.filter((_text) => !_text.isMine).findIndex((_text) => _text.link === text.link)
          // if (reFindTextIndex !== -1) {
          //   shiftedIndex = reFindTextIndex + 1
          // }

          return (
            <TextBlock
              text={text}
              profile={props.profile}
              index={index + 1}
              key={index}
              badges={badges}
              canExtraVoting
              onChangeIndexCallback={setReviewIndex}
              // shiftedIndex={shiftedIndex}
            />
          )
        })}

        <div className="texts_stat">
          <div>Всего текстов: {props.texts.length}</div>
          <div>Длина всех текстов: {props.texts.reduce((acc, text) => acc + text.characters, 0)}</div>
          <div>Средняя длина текста: {Math.floor(props.texts.reduce((acc, text) => acc + text.characters, 0) / props.texts.length)}</div>
        </div>
      </div>
    </>
  )
}
