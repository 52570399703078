import { fameUsers } from '../../../data/users'
import { NominationType } from '../../../store/profile.slice'
import { ExtraVoteByType, TextAdmin } from '../../../types/types'
import { FabricProduct } from './textsAdminTabs/AdminAchievements'

export function onlyUnique(value: string, index: number, array: string[]) {
  return array.indexOf(value) === index
}

/** Числовое выражение всех доп оценок */
export function extraRatingByCriteria(criteria: ExtraVoteByType) {
  return criteria.bad + criteria.neutral * 2 + criteria.good * 3
}

// РЕЙТИНГ ПО БАЛЛАМ

export function topTextsMethod(textsAdmin: TextAdmin[]) {
  const textSortedByRating = [...textsAdmin]
  textSortedByRating.sort(function (a: TextAdmin, b: TextAdmin) {
    const ratingA = a.rating
    const ratingB = b.rating
    const charactersA = a.characters
    const charactersB = b.characters

    if (ratingA === ratingB) {
      return charactersA < charactersB ? -1 : charactersA > charactersB ? 1 : 0
    } else {
      return ratingA > ratingB ? -1 : 1
    }
  })

  return textSortedByRating
}

// ТОПЫ ДЛЯ АЧИВОК

/** Самые активные игроки, которые натыкали больше всего кнопочек */
export function mostActiveUsersMethod(textsAdmin: TextAdmin[]): FabricProduct {
  const moreActivitySort = [...textsAdmin]
  moreActivitySort.sort(function (textA, textB) {
    const fullCriteriaA = textA.done.activities
    const fullCriteriaB = textB.done.activities
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })
  return {
    value: moreActivitySort[0].done.activities,
    users: moreActivitySort.filter((user) => user.done.activities === moreActivitySort[0].done.activities),
    iconType: 'worker',
    valueText: 'действий',
  }
}

/** Самые активные писари рецензий */
export function mostReviewWritersMethod(textsAdmin: TextAdmin[]): FabricProduct {
  const mostReviewWriters = [...textsAdmin]
  mostReviewWriters.sort(function (textA, textB) {
    const fullCriteriaA = textA.done.reviewsLength
    const fullCriteriaB = textB.done.reviewsLength
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })
  const mostReviewWritersMaxValue = mostReviewWriters.filter((user) => user.done.reviewsLength === mostReviewWriters[0].done.reviewsLength)

  return {
    value: mostReviewWritersMaxValue[0].done.reviewsLength,
    users: mostReviewWritersMaxValue.filter((user) => user.done.reviewsLength === mostReviewWritersMaxValue[0].done.reviewsLength),
    iconType: 'reviewer',
    valueText: 'символа',
  }
}

/** Метагрелочка, рецензии больше 10000 */
export function metaGrelo4kaMethod(textsAdmin: TextAdmin[]): FabricProduct {
  const metaGrelo4kaUsers = textsAdmin.filter((text) => {
    return text.done.reviewsLength >= 10000
  })

  return {
    users: metaGrelo4kaUsers,
    iconType: 'mega_reviewer',
  }
}

/** Самые активные получатели рецензий */
export function mostReviewReadersMethod(textsAdmin: TextAdmin[]): FabricProduct {
  const mostReviewReaders = [...textsAdmin]
  mostReviewReaders.sort(function (textA, textB) {
    const fullCriteriaA = textA.reviewsTexts.reduce((acc, review) => acc + review.text.length, 0)
    const fullCriteriaB = textB.reviewsTexts.reduce((acc, review) => acc + review.text.length, 0)
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })
  const mostReviewReadersMaxValue = mostReviewReaders.filter(
    (user) =>
      user.reviewsTexts.reduce((acc, review) => acc + review.text.length, 0) ===
      mostReviewReaders[0].reviewsTexts.reduce((acc, review) => acc + review.text.length, 0)
  )

  return {
    value: mostReviewReadersMaxValue[0].reviewsTexts.reduce((acc, review) => acc + review.text.length, 0),
    users: mostReviewReadersMaxValue.filter(
      (user) =>
        user.reviewsTexts.reduce((acc, review) => acc + review.text.length, 0) ===
        mostReviewReadersMaxValue[0].reviewsTexts.reduce((acc, review) => acc + review.text.length, 0)
    ),
    iconType: 'reviewed',
    valueText: 'символа',
  }
}

/** Наименее угаданные, инкогнито */
export function lessGuessedMethod(textsAdmin: TextAdmin[]): FabricProduct {
  const lessGuessedSort = [...textsAdmin]
  lessGuessedSort.sort(function (textA, textB) {
    const fullCriteriaA = textA.guesses.correct
    const fullCriteriaB = textB.guesses.correct
    if (fullCriteriaA > fullCriteriaB) return 1
    if (fullCriteriaA < fullCriteriaB) return -1
    return 0
  })
  const lessGuessedSortMinValue = lessGuessedSort.filter((user) => user.guesses.correct === lessGuessedSort[0].guesses.correct)

  return {
    value: lessGuessedSortMinValue[0].guesses.correct,
    users: lessGuessedSortMinValue.filter((user) => user.guesses.correct === lessGuessedSortMinValue[0].guesses.correct),
    iconType: 'incognita',
    valueText: 'угадаек',
  }
}

/** Наиболее угаданные, капитан очевидность */
export function mostGuessedMethod(textsAdmin: TextAdmin[]): FabricProduct {
  const mosteGuessedSort = [...textsAdmin]
  mosteGuessedSort.sort(function (textA, textB) {
    const fullCriteriaA = textA.guesses.correct
    const fullCriteriaB = textB.guesses.correct
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })
  const mostGuessedGroupMinValue = mosteGuessedSort.filter((user) => user.guesses.correct === mosteGuessedSort[0].guesses.correct)

  return {
    value: mostGuessedGroupMinValue[0].guesses.correct,
    users: mostGuessedGroupMinValue.filter((user) => user.guesses.correct === mostGuessedGroupMinValue[0].guesses.correct),
    iconType: 'captain',
    valueText: 'угадаек',
  }
}

/** Коллекционер/Хранитель ачивок */
export function achievementKeepersMethod() {
  const achievementKeepers = [...fameUsers]
  achievementKeepers.sort(function (userA, userB) {
    const fullCriteriaA = userA.achievements.filter(onlyUnique).length + userA.nominations.filter(onlyUnique).length
    const fullCriteriaB = userB.achievements.filter(onlyUnique).length + userB.nominations.filter(onlyUnique).length
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })
  const achievementKeepersGroupMaxValue = achievementKeepers.filter(
    (user) =>
      user.achievements.filter(onlyUnique).length + user.nominations.filter(onlyUnique).length ===
      achievementKeepers[0].achievements.filter(onlyUnique).length + achievementKeepers[0].nominations.filter(onlyUnique).length
  )

  const achievementKeeperMaxValue =
    achievementKeepersGroupMaxValue[0].achievements.filter(onlyUnique).length + achievementKeepersGroupMaxValue[0].nominations.filter(onlyUnique).length

  return {
    value: achievementKeeperMaxValue,
    users: achievementKeepersGroupMaxValue,
    iconType: 'achievemnt_master',
  }
}

/** Много номинаций */
export function hasManyNominationsMethod(textsAdmin: TextAdmin[], count: number, iconType: NominationType): FabricProduct {
  const hasManyNominations = textsAdmin.filter((text) => text.nominations.length >= count)
  return {
    users: hasManyNominations,
    iconType: iconType,
  }
}

/** Больше всех номинаций / Вкусная солянка */
export function goodSolyankaMethod(textsAdmin: TextAdmin[]): FabricProduct {
  const goodSolyankaSort = [...textsAdmin.filter((text) => text.nominationsWon.length > 0)]
  goodSolyankaSort.sort(function (textA, textB) {
    const fullCriteriaA = textA.nominations.filter(onlyUnique).length
    const fullCriteriaB = textB.nominations.filter(onlyUnique).length
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })
  const goodSolyankaGroupMinValue = goodSolyankaSort.filter(
    (user) => user.nominations.filter(onlyUnique).length === goodSolyankaSort[0].nominations.filter(onlyUnique).length
  )

  return {
    value: goodSolyankaGroupMinValue.length > 0 ? goodSolyankaGroupMinValue[0].nominations.filter(onlyUnique).length : 0,
    users: goodSolyankaGroupMinValue.filter(
      (user) => user.nominations.filter(onlyUnique).length === goodSolyankaGroupMinValue[0].nominations.filter(onlyUnique).length
    ),
    iconType: 'nominator_win',
    valueText: 'видов номинаций',
  }
}

/** Больше всех номинаций, но без победы / Вкусная солянка */
export function badSolyankaMethod(textsAdmin: TextAdmin[]): FabricProduct {
  const badSolyankaSort = [...textsAdmin.filter((text) => text.nominationsWon.length === 0)]
  badSolyankaSort.sort(function (textA, textB) {
    const fullCriteriaA = textA.nominations.filter(onlyUnique).length
    const fullCriteriaB = textB.nominations.filter(onlyUnique).length
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })
  const badSolyankaGroupMinValue = badSolyankaSort.filter(
    (user) => user.nominations.filter(onlyUnique).length === badSolyankaSort[0].nominations.filter(onlyUnique).length
  )

  return {
    value: badSolyankaGroupMinValue[0].nominations.filter(onlyUnique).length,
    users: badSolyankaGroupMinValue.filter(
      (user) => user.nominations.filter(onlyUnique).length === badSolyankaGroupMinValue[0].nominations.filter(onlyUnique).length
    ),
    iconType: 'nominator',
    valueText: 'видов номинаций',
  }
}

/** Собрали больше всех ставок */
export function mostBettedMethod(textsAdmin: TextAdmin[]): FabricProduct {
  const mostBettedSort = [...textsAdmin]
  mostBettedSort.sort(function (textA, textB) {
    const fullCriteriaA = textA.bets.reduce((acc, bet) => acc + bet.amount, 0)
    const fullCriteriaB = textB.bets.reduce((acc, bet) => acc + bet.amount, 0)
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })
  const mostBettedGroupMinValue = mostBettedSort.filter(
    (user) => user.bets.reduce((acc, bet) => acc + bet.amount, 0) === mostBettedSort[0].bets.reduce((acc, bet) => acc + bet.amount, 0)
  )

  return {
    value: mostBettedGroupMinValue[0].bets.reduce((acc, bet) => acc + bet.amount, 0),
    users: mostBettedGroupMinValue.filter(
      (user) => user.bets.reduce((acc, bet) => acc + bet.amount, 0) === mostBettedGroupMinValue[0].bets.reduce((acc, bet) => acc + bet.amount, 0)
    ),
    iconType: 'betwish',
    valueText: 'деняк',
  }
}

/** Длиннейшие тексты */
export function longestTextsMethod(textsAdmin: TextAdmin[]): FabricProduct {
  const longestTextsSort = [...textsAdmin.filter((text) => text.characters <= 10000)]
  longestTextsSort.sort(function (textA, textB) {
    const fullCriteriaA = textA.characters
    const fullCriteriaB = textB.characters
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })
  const longestTextsGroupMinValue = longestTextsSort.filter((user) => user.characters === longestTextsSort[0].characters)

  return {
    value: longestTextsGroupMinValue[0].characters,
    users: longestTextsGroupMinValue.filter((user) => user.characters === longestTextsGroupMinValue[0].characters),
    iconType: 'longest',
    valueText: 'знаков',
  }
}

/** Кратчайшие тексты */
export function shortestTextsMethod(textsAdmin: TextAdmin[]): FabricProduct {
  const shortestTextsSort = [...textsAdmin.filter((text) => text.characters >= 1000)]
  shortestTextsSort.sort(function (textA, textB) {
    const fullCriteriaA = textA.characters
    const fullCriteriaB = textB.characters
    if (fullCriteriaA > fullCriteriaB) return 1
    if (fullCriteriaA < fullCriteriaB) return -1
    return 0
  })
  const shortestTextsGroupMinValue = shortestTextsSort.filter((user) => user.characters === shortestTextsSort[0].characters)

  return {
    value: shortestTextsGroupMinValue[0].characters,
    users: shortestTextsGroupMinValue.filter((user) => user.characters === shortestTextsGroupMinValue[0].characters),
    iconType: 'shortest',
    valueText: 'знаков',
  }
}

/** Выжившие на грани с маленьким числом баллов тексты */
export function barelyDeadMethod(textsAdmin: TextAdmin[]): FabricProduct {
  const barelyDeadSort = [...textsAdmin.filter((text) => text.rating > 0)]
  barelyDeadSort.sort(function (textA, textB) {
    const fullCriteriaA = textA.rating
    const fullCriteriaB = textB.rating
    if (fullCriteriaA > fullCriteriaB) return 1
    if (fullCriteriaA < fullCriteriaB) return -1
    return 0
  })
  const barelyDeadGroupMinValue = barelyDeadSort.filter((user) => user.rating === barelyDeadSort[0].rating)

  return {
    value: barelyDeadGroupMinValue.length > 0 ? barelyDeadGroupMinValue[0].rating : 0,
    users: barelyDeadGroupMinValue.filter((user) => user.rating === barelyDeadGroupMinValue[0].rating),
    iconType: 'lowhp',
    valueText: 'баллов',
  }
}

/** Победившие за оценку "Общее впечатление" */
export function most_like_method(textsAdmin: TextAdmin[]): FabricProduct {
  const textsSortedBy_like = textsRatingBy_like(textsAdmin)
  const groupByMaxValue = textsSortedBy_like.filter((user) => user.extraVotes.like === textsSortedBy_like[0].extraVotes.like)

  return {
    value: extraRatingByCriteria(groupByMaxValue[0].extraVotes.like),
    users: groupByMaxValue.filter((user) => extraRatingByCriteria(user.extraVotes.like) === extraRatingByCriteria(groupByMaxValue[0].extraVotes.like)),
    iconType: 'like',
    valueText: 'оценок',
  }
}
/** Победившие за оценку "Соответствие теме" */
export function most_theme_method(textsAdmin: TextAdmin[]): FabricProduct {
  const textsSortedBy_theme = textsRatingBy_theme(textsAdmin)
  const groupByMaxValue = textsSortedBy_theme.filter((user) => user.extraVotes.theme === textsSortedBy_theme[0].extraVotes.theme)

  return {
    value: extraRatingByCriteria(groupByMaxValue[0].extraVotes.theme),
    users: groupByMaxValue.filter((user) => extraRatingByCriteria(user.extraVotes.theme) === extraRatingByCriteria(groupByMaxValue[0].extraVotes.theme)),
    iconType: 'theme',
    valueText: 'оценок',
  }
}
/** Победившие за оценку "Читабельность" */
export function most_readable_method(textsAdmin: TextAdmin[]): FabricProduct {
  const textsSortedBy_readable = textsRatingBy_readable(textsAdmin)
  const groupByMaxValue = textsSortedBy_readable.filter((user) => user.extraVotes.readable === textsSortedBy_readable[0].extraVotes.readable)

  return {
    value: extraRatingByCriteria(groupByMaxValue[0].extraVotes.readable),
    users: groupByMaxValue.filter((user) => extraRatingByCriteria(user.extraVotes.readable) === extraRatingByCriteria(groupByMaxValue[0].extraVotes.readable)),
    iconType: 'readable',
    valueText: 'оценок',
  }
}
/** Победившие за оценку "Красота текста" */
export function most_style_method(textsAdmin: TextAdmin[]): FabricProduct {
  const textsSortedBy_style = textsRatingBy_style(textsAdmin)
  const groupByMaxValue = textsSortedBy_style.filter((user) => user.extraVotes.style === textsSortedBy_style[0].extraVotes.style)

  return {
    value: extraRatingByCriteria(groupByMaxValue[0].extraVotes.style),
    users: groupByMaxValue.filter((user) => extraRatingByCriteria(user.extraVotes.style) === extraRatingByCriteria(groupByMaxValue[0].extraVotes.style)),
    iconType: 'style',
    valueText: 'оценок',
  }
}

// ТОПЫ ПО ДОПОЛНИТЕЛЬНЫМ БАЛЛАМ

/** Топ текстов по критерию "Общее впечатление" */
export function textsRatingBy_like(textsAdmin: TextAdmin[]) {
  const textsSortedBy_like = [...textsAdmin]
  textsSortedBy_like.sort(function (textA, textB) {
    const fullCriteriaA = extraRatingByCriteria(textA.extraVotes.like)
    const fullCriteriaB = extraRatingByCriteria(textB.extraVotes.like)
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })
  return textsSortedBy_like
}

/** Топ текстов по критерию "Соответствие теме" */
export function textsRatingBy_theme(textsAdmin: TextAdmin[]) {
  const textsSortedBy_theme = [...textsAdmin]
  textsSortedBy_theme.sort(function (textA, textB) {
    const fullCriteriaA = extraRatingByCriteria(textA.extraVotes.theme)
    const fullCriteriaB = extraRatingByCriteria(textB.extraVotes.theme)
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })
  return textsSortedBy_theme
}

/** Топ текстов по критерию "Читабельность" */
export function textsRatingBy_readable(textsAdmin: TextAdmin[]) {
  const textsSortedBy_readable = [...textsAdmin]
  textsSortedBy_readable.sort(function (textA, textB) {
    const fullCriteriaA = extraRatingByCriteria(textA.extraVotes.readable)
    const fullCriteriaB = extraRatingByCriteria(textB.extraVotes.readable)
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })
  return textsSortedBy_readable
}

/** Топ текстов по критерию "Красота текста" */
export function textsRatingBy_style(textsAdmin: TextAdmin[]) {
  const textsSortedBy_style = [...textsAdmin]
  textsSortedBy_style.sort(function (textA, textB) {
    const fullCriteriaA = extraRatingByCriteria(textA.extraVotes.style)
    const fullCriteriaB = extraRatingByCriteria(textB.extraVotes.style)
    if (fullCriteriaA > fullCriteriaB) return -1
    if (fullCriteriaA < fullCriteriaB) return 1
    return 0
  })
  return textsSortedBy_style
}
