import { TextAdmin } from '../../../types/types'
import style from './TextsAdmin.module.scss'
import { Tabs } from '../../ui/Tabs'
import { Tab } from '../../ui/Tab'
import { AdminTopTexts } from './textsAdminTabs/AdminTopTexts'
import { AdminAchievements } from './textsAdminTabs/AdminAchievements'
import { AdminExtraTopTexts } from './textsAdminTabs/AdminExtraTopTexts'
import { AdminDetailTexts } from './textsAdminTabs/AdminDetailTexts'
import { AdminStatistics } from './textsAdminTabs/AdminStatistics'
import { BetsState } from '../../../store/bets.slice'
import { AdminPlayer, RoundText } from './textsAdminTabs/adminControl/adminControl.types'
import { AdminPlayers } from './textsAdminTabs/adminControl/AdminPlayers'
import { Library } from '../user/hallOfFame/library/Library'
import { AdminService } from './textsAdminTabs/adminControl/AdminService'
import { PlayersInfo } from './textsAdminTabs/adminControl/PlayersInfo'
import { AdminTextGuesses } from './textsAdminTabs/AdminTextGuesses'
import { topTextsMethod } from './TextsAdmin.methods'
import { AdminTextGuessers } from './textsAdminTabs/AdminTextGuessers'

interface Props {
  textsAdmin: TextAdmin[]
  bets: BetsState
  players: AdminPlayer[]
}

export const TextsAdmin = (props: Props) => {
  const tabs = [
    { name: 'players_info', title: 'Краткая' },
    { name: 'top_texts', title: 'Топ тексты' },
    { name: 'texts_deatail', title: 'Детально' },
    { name: 'achievements', title: 'Ачивки' },
    { name: 'extra_votes', title: 'Экстра рейтинг' },
    { name: 'guesses', title: 'Угадайки' },
    { name: 'guesses2', title: 'Угадыватели' },
    { name: 'statistics', title: 'Статистика' },
    { name: 'players', title: 'Игроки' },
    { name: 'library', title: 'Библиотека' },
    { name: 'service', title: 'Сервис' },
    { name: 'archive', title: 'Архивация' },
  ]

  const libraryTexts: RoundText[] = []
  props.players.forEach((player) => {
    player.texts.forEach((text) => {
      libraryTexts.push({
        ...text,
        user_name: player.name,
      })
    })
  })

  const textSortedByRating = topTextsMethod(props.textsAdmin)

  return (
    <div className={style.textsAdmin}>
      <Tabs tabs={tabs}>
        <Tab name="players_info">
          <PlayersInfo players={props.players} />
        </Tab>
        <Tab name="top_texts">
          <AdminTopTexts textsAdmin={props.textsAdmin} />
        </Tab>
        <Tab name="texts_deatail">
          <AdminDetailTexts textsAdmin={props.textsAdmin} />
        </Tab>
        <Tab name="achievements">
          <AdminAchievements textsAdmin={props.textsAdmin} bets={props.bets} />
        </Tab>
        <Tab name="extra_votes">
          <AdminExtraTopTexts textsAdmin={props.textsAdmin} />
        </Tab>
        <Tab name="guesses">
          <AdminTextGuesses textsAdmin={props.textsAdmin} />
        </Tab>
        <Tab name="guesses2">
          <AdminTextGuessers players={props.players} />
        </Tab>
        <Tab name="statistics">
          <AdminStatistics textsAdmin={props.textsAdmin} />
        </Tab>
        <Tab name="players">
          <AdminPlayers textsAdmin={props.textsAdmin} players={props.players} />
        </Tab>
        <Tab name="library">
          <Library libraryTexts={libraryTexts} showFullStatistics />
        </Tab>
        <Tab name="service">
          <AdminService />
        </Tab>
        <Tab name="archive">
          <div>
            INSERT INTO `player_texts` (`title`, `user_name`, `round`, `characters`, `place`, `score`, `extra`, `link`) VALUES
            {textSortedByRating.map((text, index) => {
              return (
                <div key={index}>
                  ('{text.title}', '{text.realUser}', 7, {text.characters}, {index + 1}, {text.rating}, 0, '{text.link.replace('reader', 'library')}'),
                </div>
              )
            })}
            ;
          </div>
        </Tab>
      </Tabs>
    </div>
  )
}
