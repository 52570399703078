import React from 'react'
import style from './Editor.module.scss'
import { Editor } from '@tinymce/tinymce-react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useOnload } from '../../../hooks/useOnload'
import { deleteTextApi, getTextApi, saveTextApi } from '../../../api/editor.api'

interface Props {}

export const EditorComponent = (props: Props) => {
  let { guid = '', textguid = '' } = useParams()
  const navigate = useNavigate()

  const editorRef = React.useRef<Editor>(null)
  const [titleInput, set_titleInput] = React.useState('')
  const [textHTML, set_textHTML] = React.useState('')
  const [characters, set_characters] = React.useState(0)
  const [isChanged, set_isChanged] = React.useState(false)
  const [accessError, set_accessError] = React.useState('')

  React.useEffect(() => {
    // on load text
  }, [guid])

  React.useEffect(() => {
    setTimeout(() => {
      const statusbar = document.getElementsByClassName('tox-statusbar')[0]
      if (statusbar) {
        statusbar.remove()
      }
    }, 1000)
  }, [])

  useOnload(() => {
    getTextApi(guid, textguid)
      .then((response) => {
        const editorText = response.data
        // editorRef.current.
        set_textHTML(editorText.text)
        set_titleInput(editorText.title)
        set_characters(editorText.characters)
      })
      .catch((error) => {
        const message = error.response.data.message
        switch (message) {
          case 'forbidden':
            console.error('Не удалось загрузить текст, нет доступа')
            set_accessError('forbidden')
            break
          case 'transfered':
            console.error('Не удалось загрузить текст, он уже перемещён')
            set_accessError('transfered')
            break
          default:
            console.error('Не удалось загрузить текст, но почему - никто не знает')
            set_accessError('unknown')
            break
        }
      })
  })

  function getCharactersWithEditor() {
    // @ts-ignore
    if (editorRef.current && editorRef.current.plugins && editorRef.current.plugins.wordcount) {
      // @ts-ignore
      return editorRef.current.plugins.wordcount.body.getCharacterCount()
    } else {
      return 0
    }
  }

  function saveText() {
    // @ts-ignore
    const characters = getCharactersWithEditor()

    if (characters > 10000) {
      return
    }

    const params = {
      guid,
      textguid,
      title: titleInput,
      characters,
      text: textHTML,
    }

    saveTextApi(params).then((response) => {
      set_isChanged(false)
    })
  }

  function onChangeText(event: any) {
    set_textHTML(event.target.innerHTML)
    const characters = getCharactersWithEditor()
    set_characters(characters)
    set_isChanged(true)
  }

  function onChangeTitle(event: any) {
    set_titleInput(event.target.value)
    set_isChanged(true)
  }

  function confirmDeleteText() {
    if (window.confirm('Удалить текст? Его нельзя будет восстановить!')) {
      deleteTextApi({
        guid,
        textguid,
      }).then(() => {
        navigate(`/editor/${guid}`)
      })
    }
  }

  const overLimit = characters > 10000

  if (accessError) {
    return (
      <>
        <div className={style.accessError}>
          <h2>Редактор текстов</h2>
          {accessError == 'forbidden' && (
            <>
              <div className={style.error}>Доступ к данному тексту невозможен</div>
            </>
          )}
          {accessError == 'transfered' && (
            <>
              <div className={style.error}>Этот текст уже перенесён в игру</div>
            </>
          )}
          {accessError == 'unknown' && (
            <>
              <div className={style.error}>Этот текст не открывается, но неизвестно почему :С</div>
            </>
          )}
          <div className={style.backLink}>{!isChanged && <Link to={`/editor/${guid}`}>Вернуться к списку текстов</Link>}</div>
        </div>
      </>
    )
  }

  return (
    <>
      <div className={style.editor}>
        <div className={style.wrapper}>
          <h2>Редактор текстов</h2>
          <div className={style.backLink}>{!isChanged && <Link to={`/editor/${guid}`}>Вернуться к списку текстов</Link>}</div>
          <div className={style.title}>
            <div>Название:</div>
            <input type="text" value={titleInput} onChange={(event) => onChangeTitle(event)} />
          </div>
          <Editor
            apiKey="5n1w0bg374qf5utbvdqo9f0r8p9tus5rco9s1wqtm4jwvbd8"
            //@ts-ignore
            onInit={(_evt, editor) => (editorRef.current = editor)}
            value={textHTML}
            initialValue={'Напиши свой лучший текст!'}
            onKeyUp={(event) => onChangeText(event)}
            onInput={(event) => onChangeText(event)}
            init={{
              height: '70vh',
              menubar: false,
              plugins: ['wordcount'],
              toolbar: 'undo redo | blocks | bold italic underline | alignleft aligncenter ',
              content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          />
          {/* <div>text: {textHTML}</div> */}
          <div className={style.footer}>
            <div className={style.characters}>{characters} / 10 000 знаков</div>
            <button onClick={saveText} disabled={overLimit || !isChanged}>
              Сохранить
            </button>
            {!overLimit && isChanged && <div className={style.informer}>Не забудь сохранить свой лучший в мире текст!</div>}
            {!overLimit && !isChanged && <div className={style.informer}>Текст сохранён!</div>}
            {overLimit && <div className={style.informer}>Слишком длинный текст!</div>}
            <hr />
            <button className={style.deleteButton} onClick={confirmDeleteText}>
              Удалить текст
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
