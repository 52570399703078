import { NominationType } from '../store/profile.slice'

export interface NominationItem {
  type: NominationType
  title: string
  description: string
  icon: string
  itemType: 'nomination' | 'achievement'
  achievemntType?: 'user' | 'table' | 'text' | 'other' | 'clans'
  achievemntPeriod?: 'permanent' | 'variable'
  customIcon?: string
  customButtonText?: string
  rare?: boolean
  epic?: boolean
}

export const nominationsList: NominationItem[] = [
  { type: 'standup', title: 'Стендап', description: 'Самый смешной текст', icon: '😁', itemType: 'nomination' },
  { type: 'drama', title: 'Драматург', description: 'Самый драматичный текст, заставивший испытать бурю эмоций', icon: '😭', itemType: 'nomination' },
  {
    type: 'nabokov',
    title: 'Филолог',
    description: 'Самые красивые или необычные словесные обороты, хорошая работа над слогом',
    icon: '🤓',
    itemType: 'nomination',
  },
  { type: 'mimimi', title: 'Мимими', description: 'Самый милый текст :3', icon: '🥰', itemType: 'nomination' },
  { type: 'chef', title: 'Шеф-повар', description: 'Текст, в котором было много еды', icon: '🍔', itemType: 'nomination' },
  { type: 'ogo', title: 'Ого!', description: 'Текст, в сюжете которого было что-то очень неожиданное', icon: '😮', itemType: 'nomination' },
  { type: 'not_ready', title: 'Мир ещё не готов', description: 'Самый недопонятый с точки зрения сюжета или смысла текст', icon: '🧐', itemType: 'nomination' },
  { type: 'literally', title: 'В лоб', description: 'Буквальная интерпретация темы, которую даже не пришлось искать', icon: '😎', itemType: 'nomination' },
  { type: 'interesting', title: 'Глубоко', description: 'Неочевидная интерпретация темы, которую пришлось поискать', icon: '👽', itemType: 'nomination' },
  { type: 'normie', title: 'Нормис', description: 'Самый нормальный текст. Что бы это значило?', icon: '😐', itemType: 'nomination' },
  { type: 'light', title: 'Воин света', description: 'Самый светлый текст', icon: '🙏', itemType: 'nomination' },
  { type: 'dark', title: 'Воин тьмы', description: 'Самый мрачный текст', icon: '👹', itemType: 'nomination' },
  // { type: 'hot', title: 'Жаркий текст', description: 'Было жарко! 🔥', icon: '🔥', itemType: 'nomination' },
  // { type: 'cold', title: 'Холодный текст', description: 'Было холодно... ❄️', icon: '❄️', itemType: 'nomination' },
  { type: 'scary', title: 'Страшно', description: 'Этот текст был страшным, я немножко сренькнул', icon: '😱', itemType: 'nomination' },
  { type: 'cosy', title: 'Уютно', description: 'В этом текст было уютненько находиться', icon: '🤗', itemType: 'nomination' },
  { type: 'hard', title: 'Сложно', description: 'Сложна! Нипанятна! Я ничего не понял :(', icon: '🗿', itemType: 'nomination' },
  {
    type: 'lore',
    title: 'Лорно',
    description: 'Лор принимает только с медицинским полисом! В этом тексте было много специфических лорных деталей',
    icon: '⚙️',
    itemType: 'nomination',
  },
  // { type: 'less_dialogs', title: 'А поговорить?', description: 'В тексте было мало диалогов или не было вовсе', icon: '😶', itemType: 'nomination' },
  // { type: 'many_dialogs', title: 'Больше дела!', description: 'В тексте было слишком много диалогов', icon: '🥱', itemType: 'nomination' },
  {
    type: 'educational',
    title: 'Познавательно',
    description: 'В тексте было то, что интересно узнать. Этим текстом можно заменить один курс высшего образования',
    icon: '🤔',
    itemType: 'nomination',
  },
  { type: 'massacre', title: 'РЕЗНЯ', description: 'Убивать! Убивать! Убивать! Убивать! Убивать!!!', icon: '🔪', itemType: 'nomination' },
  // { type: 'horny', title: 'Хорни', description: '', icon: '😳', itemType: 'nomination' },
]

export const achievementsList: NominationItem[] = [
  {
    type: '1st',
    title: 'Первое место',
    description: 'Первое место в любой игре',
    icon: '🥇',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'permanent',
  },
  {
    type: '2nd',
    title: 'Второе место',
    description: 'Второе место в любой игре',
    icon: '🥈',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'permanent',
  },
  {
    type: '3rd',
    title: 'Третье место',
    description: 'Третье место в любой игре',
    icon: '🥉',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'veteran',
    title: 'Ветеран',
    description: 'Участвует во всех играх сезона с самого начала. Эта ачивка будет потеряна, если пропустить хотя бы одну игру!',
    icon: '🏅',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'variable',
    customButtonText: 'Моё уважение!',
    epic: true,
  },
  {
    type: 'streak',
    title: 'Гонщик',
    description: 'Не пропускал ни одной грелочки с тех пор, как начал играть',
    icon: '⛵️',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'variable',
  },
  {
    type: 'dragon',
    title: 'Дракон',
    description: 'Древний, как дракон. Играл в ту самую первую грелочку!',
    icon: '🐲',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'permanent',
    customButtonText: 'Моё почтение!',
    epic: true,
  },
  {
    type: 'pass',
    title: 'Прогульщик',
    description: 'Пропустил как минимум одну игру, но вернулся и играл снова',
    icon: '😓',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'permanent',
  },
  // { type: 'leave', title: 'Сломленный', description: 'Пытался, но не смог написать текст...', icon: '🎗', itemType: 'achievement' },
  {
    type: 'dead',
    title: 'Житель могилы',
    description: 'Отдых в комфортных подземных апартаментах (набрано 0 баллов)',
    icon: '💀',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'lowhp',
    title: 'Выживший',
    description: 'На самом краю! Не был в могиле, но набрал меньше всех баллов в игре',
    icon: '👀',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'ban',
    title: 'Жертва дедлайна',
    description: 'Не успел проголосовать',
    icon: '☠️',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'shortest',
    title: 'Пчола',
    description: 'Текст был короче остальных (но не нарушил правила)',
    icon: '🐝',
    itemType: 'achievement',
    achievemntType: 'text',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'longest',
    title: 'Слоняра',
    description: 'Текст был длиннее остальных (но не нарушил правила)',
    icon: '🐘',
    itemType: 'achievement',
    achievemntType: 'text',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'oversize',
    title: 'Невпихуемое',
    description: 'Текст вышел за лимиты в 10000 знаков',
    icon: '🐳',
    itemType: 'achievement',
    achievemntType: 'text',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'graphoman',
    title: 'Графоман',
    description: 'Сдал больше одного текста',
    icon: '😎',
    itemType: 'achievement',
    achievemntType: 'text',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'pushok',
    title: 'Пушок',
    description: 'Выдаётся за убеждения',
    icon: '',
    itemType: 'achievement',
    achievemntType: 'other',
    achievemntPeriod: 'variable',
    customIcon: 'pushok',
    rare: true,
  },
  {
    type: 'potato',
    title: 'Орден драника',
    description: 'Написал текст про драники (когда же это кончится?)',
    icon: '🌚',
    itemType: 'achievement',
    achievemntType: 'other',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'illustrator',
    title: 'Иллюстратор',
    description: 'Добавил иллюстрацию к своему тексту. Учитываются только информативные иллюстрация, но не мемы',
    icon: '🎨',
    itemType: 'achievement',
    achievemntType: 'text',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'dominant',
    title: 'Доминатор',
    description: 'Удерживал призовое место более 1 игры подряд',
    icon: '👑',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'permanent',
    rare: true,
  },
  {
    type: 'seasonwinner',
    title: 'Победитель сезона',
    description: 'Выиграл в сезоне по числу баллов',
    icon: '🏆',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'permanent',
    epic: true,
  },
  {
    type: 'other',
    title: 'Иной',
    description: 'Написал что-то очень необычное или в необычном формате',
    icon: '🍄',
    itemType: 'achievement',
    achievemntType: 'text',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'like',
    title: 'Молодец',
    description: 'Получил наибольшую оценку в категории "Общее впечатление"',
    icon: '💛',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
    rare: true,
  },
  {
    type: 'theme',
    title: 'Выкупил тему',
    description: 'Получил наибольшую оценку в категории "Соответствие теме"',
    icon: '🎯',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
    rare: true,
  },
  {
    type: 'readable',
    title: 'Всё понятно',
    description: 'Получил наибольшую оценку в категории "Читабельность"',
    icon: '☀️',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
    rare: true,
  },
  {
    type: 'style',
    title: 'Стиляга',
    description: 'Получил наибольшую оценку в категории "Красота текста"',
    icon: '✨',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
    rare: true,
  },
  {
    type: 'extra_master',
    title: 'Четыре стихии',
    description: 'Собрал все четыре ачивки оценок: "Общее впечатление", "Соответствие теме", "Читабельность", "Красота текста"',
    icon: '',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
    customIcon: 'extra_master',
    epic: true,
  },
  {
    type: 'nominant10',
    title: 'Номинант',
    description: 'Собрал 10 номинаций за одну игру',
    icon: '🌵',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'nominant20',
    title: 'Меганоминант',
    description: 'Собрал 20 номинаций за одну игру',
    icon: '🎄',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
    rare: true,
  },
  // {
  //   type: 'nominant30',
  //   title: 'Гиганоминант',
  //   description: 'Собрал 30 номинаций за одну игру',
  //   icon: '🌴',
  //   itemType: 'achievement',
  //   achievemntType: 'user',
  //   achievemntPeriod: 'permanent',
  //   epic: true,
  // },
  {
    type: 'nominator_win',
    title: 'Вкусная солянка',
    description: 'Собрал больше всех видов номинаций за одну игру и выиграл хотя бы одну',
    icon: '🍜',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'nominator',
    title: 'Невкусная солянка',
    description: 'Собрал больше всех видов номинаций за одну игру, но ни одну не выиграл',
    icon: '🍲',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
  },
  // {
  //   type: 'nominantion_way',
  //   title: 'Таков путь',
  //   description: 'Трижды получил одну номинацию, настоящий мастер жанра!',
  //   icon: '☘️',
  //   itemType: 'achievement',
  //   achievemntType: 'user',
  //   achievemntPeriod: 'permanent',
  //   rare: true,
  // },
  {
    type: 'no_nominations',
    title: 'Я выше этого',
    description: 'Не получил ни одной номинации за игру, потому что не очень-то и хотелось!',
    icon: '🙅',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'betwin',
    title: 'Поднял бабла',
    description: 'Выиграл на ставках',
    icon: '💸',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
  },
  // {
  //   type: 'betlose',
  //   title: 'Без штанов',
  //   description: 'Проиграл на ставках',
  //   icon: '🎺',
  //   itemType: 'achievement',
  //   achievemntType: 'user',
  //   achievemntPeriod: 'permanent',
  // },
  {
    type: '1season',
    title: 'Игрок 1 сезона',
    description: 'Играл в 1 сезоне грелочки',
    icon: '1️⃣',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'permanent',
  },
  {
    type: '2season',
    title: 'Игрок 2 сезона',
    description: 'Играл во 2 сезоне грелочки',
    icon: '2️⃣',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'incognita',
    title: 'Инкогнито',
    description: 'Самый зашифрованный участник, которого не угадал никто или почти никто',
    icon: '❓',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'captain',
    title: 'Капитан очевидность',
    description: 'Самый очевидный участник, которого угадали все или почти все',
    icon: '🎀',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'score_gap',
    title: 'На Марс',
    description: 'Занял первое место и по количеству баллов опередил второго победителя минимум в два раза',
    icon: '🚀',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'permanent',
    epic: true,
  },
  {
    type: 'worker',
    title: 'Работяга',
    description: 'Нажал все кнопочки, какие только можно! Рецензии не учитываюся, для них другая ачивка',
    icon: '👷🏻‍♀️',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'reviewer',
    title: 'Критик',
    description: 'Написал больше всех рецензий',
    icon: '✉️',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'reviewed',
    title: 'Любимец прессы',
    description: 'Получил больше всех рецензий',
    icon: '📸',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'mega_reviewer',
    title: 'Метагрелочка',
    description: 'Написал рецензий на 10 тысяч знаков или больше',
    icon: '📚',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'reviews_both',
    title: 'Эпистолярный роман',
    description: 'Написал больше всех рецензий и получил тоже больше всех рецензий, какая общительность!',
    icon: '💌',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
    epic: true,
  },
  {
    type: 'achievemnt_master',
    title: 'Манчкин',
    description: 'Имеет больше всех разных видов ачивок! Ачивка может быть потеряна, если кто-то насобирает больше',
    icon: '🛒',
    itemType: 'achievement',
    achievemntType: 'other',
    achievemntPeriod: 'variable',
    customButtonText: 'Я соберу их все!',
    epic: true,
  },
  {
    type: 'ufo',
    title: 'Уфолог',
    description: 'Нашёл все три секретные летающие тарелки в 6 грелочке',
    icon: '🛸',
    itemType: 'achievement',
    achievemntType: 'other',
    achievemntPeriod: 'permanent',
    customIcon: 'ufo',
    rare: true,
  },
  {
    type: 'dead_master',
    title: 'Директор кладбища',
    description: 'Провёл больше всего времени в могиле и знает, как лечь поудобнее! Ачивка может быть потеряна, если кто-то наумирает больше',
    icon: '⚰️',
    itemType: 'achievement',
    achievemntType: 'table',
    achievemntPeriod: 'variable',
    customButtonText: 'F...',
    rare: true,
  },
  {
    type: 'oracle',
    title: 'Гадалка',
    description: 'Угадал больше всех участников',
    icon: '🔮',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
  },
  {
    type: 'betwish',
    title: 'Гроза биржи',
    description: 'На него ставили больше всего. Выиграли ли? Это уже не важно',
    icon: '🤑',
    itemType: 'achievement',
    achievemntType: 'user',
    achievemntPeriod: 'permanent',
    rare: true,
  },
  {
    type: 'clan_askarons',
    title: 'Племя Аскаронов',
    description: 'Сражался за племя Аскаронов',
    icon: '',
    itemType: 'achievement',
    achievemntType: 'clans',
    achievemntPeriod: 'permanent',
    customIcon: 'askarons3',
  },
  {
    type: 'clan_pushki',
    title: 'Племя Пушков',
    description: 'Сражался за племя Пушков',
    icon: '',
    itemType: 'achievement',
    achievemntType: 'clans',
    achievemntPeriod: 'permanent',
    customIcon: 'pushki',
  },
  {
    type: 'clan_draniki',
    title: 'Племя Драников',
    description: 'Сражался за племя Драников',
    icon: '',
    itemType: 'achievement',
    achievemntType: 'clans',
    achievemntPeriod: 'permanent',
    customIcon: 'draniki',
  },
  {
    type: 'clan_beses',
    title: 'Племя Бесов',
    description: 'Сражался за племя Бесов',
    icon: '',
    itemType: 'achievement',
    achievemntType: 'clans',
    achievemntPeriod: 'permanent',
    customIcon: 'beses',
  },
  {
    type: 'clan',
    title: 'Командный игрок',
    description: 'Принёс своему племени победу',
    icon: '📢',
    itemType: 'achievement',
    achievemntType: 'clans',
    achievemntPeriod: 'permanent',
  },
]
