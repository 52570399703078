import React from 'react'
import style from './HallOfFame.module.scss'
import { pluralSets, pluralText } from '../../../../methods/text.methods'
import cn from 'classnames'
import { RoundText } from '../../admin/textsAdminTabs/adminControl/adminControl.types'

interface Props {
  round: number
  texts: RoundText[]
  isSkipped: boolean
  isPlaying: boolean
}

export const RoundTextRow = (props: Props) => {
  const { round, texts, isSkipped, isPlaying } = props

  if (isSkipped) {
    return <div className={style.noText}>★ Грелочка {props.round}: Не участвует ❌</div>
  }

  if (isPlaying) {
    return <div className={style.noText}>★ Грелочка {props.round}: Участвует сейчас ⏳</div>
  }

  return (
    <>
      <div className={style.playerRound}>
        {texts.length === 0 && <div className={style.playerRoundTitle}>В Грелочке {round} не участвует</div>}
        {texts.length > 0 && <div className={style.playerRoundTitle}>Грелочка {round}</div>}
        <div className={style.playerRoundTexts}>
          {texts.map((text, index) => {
            const isDead = text.place === -1
            const isBan = text.place === -2
            return (
              <div key={index} className={style.playerRoundText}>
                {text.place > 0 && (
                  <div
                    className={cn(style.badge, {
                      [style.gold]: text.place === 1,
                      [style.silver]: text.place === 2,
                      [style.copper]: text.place === 3,
                      [style.gray]: text.place > 3,
                    })}
                  >
                    {text.place} место
                  </div>
                )}
                {isDead && <div className={cn(style.badge, style.black)}>Могила 💀</div>}
                {isBan && <div className={cn(style.badge, style.black)}>Бан 💀</div>}
                <div>
                  <a href={text.link} target="_blank" rel="noreferrer">
                    {text.title}
                  </a>
                </div>
                {text.score > 0 && (
                  <div>
                    {pluralText(text.score, pluralSets.balls)} {text.extra ? `не в зачёт` : ''}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
