import { AxiosResponse } from 'axios'
import { axiosInstance } from './adapter'
import { ArchiveReview, Guid, HallOfFamePlayer, Text } from '../types/types'
import { RoundText } from '../components/pages/admin/textsAdminTabs/adminControl/adminControl.types'
import { EditorText, EditorTextForReader } from '../types/editor.types'

export interface GetLibraryResponse {
  players: HallOfFamePlayer[]
  library: RoundText[]
  reviews: {
    fromMe: ArchiveReview[]
    toMe: ArchiveReview[]
  }
}

interface GetMyTextsApiResponse {
  editor: EditorText[]
  google: Text[]
}

export function getMyTextsApi(guid: Guid): Promise<AxiosResponse<GetMyTextsApiResponse, any>> {
  return axiosInstance.get(`/editor/my_texts/${guid}`)
}

export function createTextApi(guid: Guid): Promise<AxiosResponse<Guid, any>> {
  return axiosInstance.post(`/editor/create/`, {
    guid,
  })
}

export function getTextApi(guid: Guid, textGuid: Guid): Promise<AxiosResponse<EditorText, any>> {
  return axiosInstance.get(`/editor/open/${guid}/${textGuid}`)
}
export function getTextForReaderApi(textGuid: Guid): Promise<AxiosResponse<EditorTextForReader, any>> {
  return axiosInstance.get(`/reader/${textGuid}`)
}

export function getTextForLibraryApi(textGuid: Guid): Promise<AxiosResponse<EditorTextForReader, any>> {
  return axiosInstance.get(`/reader_archive/${textGuid}`)
}

interface SaveTextBody {
  guid: string
  textguid: string
  title: string
  characters: number
  text: string
}

export function saveTextApi(body: SaveTextBody): Promise<AxiosResponse<boolean, any>> {
  return axiosInstance.post(`/editor/save/`, body)
}

interface DeleteTextBody {
  guid: string
  textguid: string
}

export function deleteTextApi(body: DeleteTextBody): Promise<AxiosResponse<boolean, any>> {
  return axiosInstance.post(`/editor/delete/`, body)
}
