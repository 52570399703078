import React from 'react'
import { RulesBase } from '../user/RulesBase'
import style from './Invite.module.scss'
import { postContactApi as postInviteApi } from '../../../api/user.api'
import cn from 'classnames'

export const Invite = () => {
  const [contact, set_contact] = React.useState('')
  const [about, set_about] = React.useState('')
  const [isSent, set_isSent] = React.useState(false)

  function onClickInviteButton() {
    if (contact.length > 250) return
    if (!contact.trim()) return

    postInviteApi(contact, about)
      .then(() => {
        set_contact('')
        set_about('')
        set_isSent(true)
      })
      .catch(() => {
        alert('Что-то пошло не так... мы тебя не найдём :(')
      })
  }

  return (
    <>
      <div className={style.invite}>
        <h1>Привет!</h1>

        <h2>Мы набираем участников для второго сезона "Грелочки"!</h2>

        <RulesBase />

        <div className={style.statistics}>
          <div className={style.statBlock}>
            <div className={style.title}>Грелочек прошло</div>
            <div className={style.value}>6</div>
          </div>

          <div className={style.statBlock}>
            <div className={style.title}>Участников было</div>
            <div className={style.value}>35</div>
          </div>

          <div className={style.statBlock}>
            <div className={style.title}>Текстов написано</div>
            <div className={style.value}>124</div>
          </div>
        </div>

        {!isSent && (
          <div className={style.contact}>
            <div>Если эта страница была обнаружена тобой без явного указания, куда писать, отправляй свои контактные данные в форму ниже</div>
            <div>Мы найдём тебя и заберём в "Грелочку"!</div>
            <input type="text" value={contact} onChange={(event) => set_contact(event.target.value)} maxLength={250} />
            <div
              className={cn(style.about, {
                [style.hidden]: !contact.trim(),
                [style.showed]: contact.trim().length > 0,
              })}
            >
              <div>А ещё можешь немного рассказать о себе:</div>
              <textarea value={about} onChange={(event) => set_about(event.target.value)}></textarea>
            </div>
            <button onClick={onClickInviteButton} disabled={!contact.trim()}>
              Найдите меня!
            </button>
          </div>
        )}
        {isSent && (
          <div className={style.isSent}>
            <div className={style.icon}>👀</div>
            <div className={style.text}>Мы найдём тебя!</div>
          </div>
        )}

        <div className={style.gif}>
          <img src="/img/loading.gif" alt="pushok" />
          <div>А это Пушок</div>
        </div>
      </div>
    </>
  )
}
