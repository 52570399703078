import style from './RoundTextComponent.module.scss'
import cn from 'classnames'
import { RoundText } from './adminControl.types'
import { pluralSets, pluralText } from '../../../../../methods/text.methods'

interface RoundTextComponentProps {
  text: RoundText
  showName?: boolean
}

export const RoundTextComponent = (props: RoundTextComponentProps) => {
  const { text, showName } = props

  return (
    <div
      className={cn(style.text, {
        [style.green]: text.place === 0,
      })}
    >
      <div className={style.textTitle}>
        <div>
          {text.link && (
            <a href={text.link} target="_blank" rel="noreferrer">
              {text.title}
            </a>
          )}
          {!text.link && <span>{text.title} (ссылка потеряна)</span>}
        </div>
        <div>
          <span className={style.textRound}>{text.round} игра</span>
        </div>
      </div>

      <div className={style.name}>{showName && <div>{text.user_name}</div>}</div>

      <div className={style.textPlace}>
        {pluralText(text.characters, pluralSets.characters)}
        {text.place > 0 ? `, ${text.place} место` : ''}
        {text.score === 0 ? `, 💀` : ''}
        {text.score > 0 ? `, ${pluralText(text.score, pluralSets.balls)}` : ''}
      </div>

      {text.place === 0 && <div>В игре!</div>}
    </div>
  )
}
