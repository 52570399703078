import React from 'react'
import { NominationItem } from '../../../../data/nominations'
import style from './NominationBlock.module.scss'
import cn from 'classnames'

interface Props {
  count: number
  withBg?: boolean
  item: NominationItem
  isWin?: boolean
  smallMode?: boolean
  extraSmallMode?: boolean
}

export const NominationBlock = (props: Props) => {
  const [showModal, set_showModal] = React.useState(false)

  function toggleModal() {
    set_showModal(!showModal)
  }

  return (
    <>
      <div className={style.wrapper}>
        <div
          className={cn(style.nomination, {
            [style.bg]: props.withBg,
            [style.border]: props.isWin,
            [style.small]: props.smallMode,
            [style.extraSmall]: props.extraSmallMode,
          })}
          onClick={toggleModal}
        >
          {!props.item.customIcon && <div className={style.icon}>{props.item.icon}</div>}
          {props.item.customIcon && (
            <img
              className={cn(style.imgIcon, {
                [style.imgIconSmall]: props.smallMode,
                [style.imgIconExtraSmall]: props.extraSmallMode,
              })}
              src={`/img/icons/${props.item.customIcon}.png`}
              alt="icon"
            />
          )}
          {!props.smallMode && !props.extraSmallMode && <div className={style.title}>{props.item.title}</div>}
          <div className={style.count}>{props.count > 1 ? `✖${props.count}` : ''}</div>
        </div>
        {props.isWin && <div className={style.winner}>Победа</div>}
      </div>

      {showModal && (
        <div className={style.infoModal}>
          <div className={style.info}>
            <p className={style.itemType}>{props.item.itemType === 'achievement' ? 'ачивка' : 'номинация'}</p>
            {props.item.rare && <p>Редкая</p>}
            {props.item.epic && <p>Эпичная</p>}
            {!props.item.customIcon && <h2>{props.item.icon}</h2>}
            {props.item.customIcon && (
              <img
                className={cn(style.imgIcon, { TiltAnimation: props.item.type === 'pushok', FloatAnimation: props.item.type === 'ufo' })}
                src={`/img/icons/${props.item.customIcon}.png`}
                alt="icon"
              />
            )}
            <h3>{props.item.title}</h3>
            <p>{props.item.description}</p>
            <div>★</div>
            {props.item.itemType === 'achievement' && <p>Ачивки выдаются админом вручную на основе турнирной таблицы и событий</p>}
            {props.item.itemType === 'nomination' && <p>Номинации выдаются участниками на этапе чтения и голосования</p>}

            {props.isWin && <div>★</div>}
            {props.isWin && (
              <div className={style.winText}>
                <div>Эта номинация была выиграна</div>
                <br />
                <div>Она теперь навсегда</div>
              </div>
            )}
            {props.item.itemType === 'achievement' && (
              <div className={style.itemPeriodType}>
                {props.item.achievemntPeriod === 'permanent' ? 'Эта ачивка выдана навсегда' : 'Эта ачивка может быть потеряна'}
              </div>
            )}
            <button onClick={toggleModal}>{props.item.customButtonText || 'Теперь понятно!'}</button>
          </div>
        </div>
      )}
    </>
  )
}
