import cn from 'classnames'
import style from '../TextsAdmin.module.scss'
import { TextAdmin } from '../../../../types/types'
import { pluralSets, pluralText } from '../../../../methods/text.methods'
import { extraRatingByCriteria, textsRatingBy_like, textsRatingBy_readable, textsRatingBy_style, textsRatingBy_theme } from '../TextsAdmin.methods'

interface Props {
  textsAdmin: TextAdmin[]
}

export const AdminExtraTopTexts = (props: Props) => {
  const top_like = textsRatingBy_like(props.textsAdmin)
  const top_theme = textsRatingBy_theme(props.textsAdmin)
  const top_readable = textsRatingBy_readable(props.textsAdmin)
  const top_style = textsRatingBy_style(props.textsAdmin)

  return (
    <div className={style.tops}>
      <h2>Экстра рейтинг</h2>
      <h3>Общее впечатление:</h3>
      {top_like.map((text, index) => {
        const total = extraRatingByCriteria(text.extraVotes.like)
        return (
          <div
            key={index}
            className={cn(style.topLine, {
              [style.mine]: text.isMine,
            })}
          >
            <div>#{index + 1}</div>
            <div>{pluralText(total, pluralSets.balls)}</div>
            <div>
              <a href={text.link} target="_blank" rel="noopener noreferrer">
                «{text.title}»
              </a>
            </div>
            <div>
              {text.user} aka {text.realUser} ({text.user_id})
            </div>
          </div>
        )
      })}

      <h3>Соответствие теме:</h3>
      {top_theme.map((text, index) => {
        const total = extraRatingByCriteria(text.extraVotes.theme)
        return (
          <div
            key={index}
            className={cn(style.topLine, {
              [style.mine]: text.isMine,
            })}
          >
            <div>#{index + 1}</div>
            <div>{pluralText(total, pluralSets.balls)}</div>
            <div>
              <a href={text.link} target="_blank" rel="noopener noreferrer">
                «{text.title}»
              </a>
            </div>
            <div>
              {text.user} aka {text.realUser} ({text.user_id})
            </div>
          </div>
        )
      })}

      <h3>Читабельность:</h3>
      {top_readable.map((text, index) => {
        const total = extraRatingByCriteria(text.extraVotes.readable)
        return (
          <div
            key={index}
            className={cn(style.topLine, {
              [style.mine]: text.isMine,
            })}
          >
            <div>#{index + 1}</div>
            <div>{pluralText(total, pluralSets.balls)}</div>
            <div>
              <a href={text.link} target="_blank" rel="noopener noreferrer">
                «{text.title}»
              </a>
            </div>
            <div>
              {text.user} aka {text.realUser} ({text.user_id})
            </div>
          </div>
        )
      })}

      <h3>Красота текста:</h3>
      {top_style.map((text, index) => {
        const total = extraRatingByCriteria(text.extraVotes.style)
        return (
          <div
            key={index}
            className={cn(style.topLine, {
              [style.mine]: text.isMine,
            })}
          >
            <div>#{index + 1}</div>
            <div>{pluralText(total, pluralSets.balls)}</div>
            <div>
              <a href={text.link} target="_blank" rel="noopener noreferrer">
                «{text.title}»
              </a>
            </div>
            <div>
              {text.user} aka {text.realUser} ({text.user_id})
            </div>
          </div>
        )
      })}
    </div>
  )
}
