import React from 'react'
import cn from 'classnames'
import style from '../TextsAdmin.module.scss'
import { ActionType, TextAdmin } from '../../../../types/types'
import { pluralSets, pluralText } from '../../../../methods/text.methods'
import { extraRatingByCriteria, topTextsMethod } from '../TextsAdmin.methods'
import { NominationBlock } from '../../user/components/NominationBlock'
import { NominationItem, nominationsList } from '../../../../data/nominations'
import { NominationsList } from '../../user/components/NominationsList'

interface Props {
  textsAdmin: TextAdmin[]
}

export const AdminTopTexts = (props: Props) => {
  const [showNickname, set_showNickname] = React.useState(true)
  const [showRealname, set_showRealname] = React.useState(true)
  const [showTgid, set_showTgid] = React.useState(true)
  const [showInfo, set_showInfo] = React.useState(false)

  function toggle_showNickname() {
    set_showNickname(!showNickname)
  }
  function toggle_showRealname() {
    set_showRealname(!showRealname)
  }
  function toggle_showTgid() {
    set_showTgid(!showTgid)
  }
  function toggle_showInfo() {
    set_showInfo(!showInfo)
  }

  const textSortedByRating = topTextsMethod(props.textsAdmin)

  const nominationsAll = nominationsList.map((nom) => nom.type)
  const nominationsWon = props.textsAdmin.map((text) => text.nominationsWon).flat()
  const nominationsIdle = nominationsAll.filter((x) => !nominationsWon.includes(x))

  return (
    <div className={style.topsTexts}>
      <h2>Топ тексты</h2>

      <div className={style.cheat}>
        угадал | был угадан | like-theme-readable-style | номинации | пчелокоины | арбузокоины | рецензии | всего активностей сделано
      </div>
      <div className={style.cheat}>
        <label htmlFor="nickname">Никнейм:</label>
        <input type="checkbox" id="nickname" checked={showNickname} onChange={toggle_showNickname} /> | <label htmlFor="realname">Реальное имя:</label>
        <input type="checkbox" id="realname" checked={showRealname} onChange={toggle_showRealname} /> | <label htmlFor="tgid">TG id:</label>
        <input type="checkbox" id="tgid" checked={showTgid} onChange={toggle_showTgid} /> | <label htmlFor="info">info:</label>
        <input type="checkbox" id="info" checked={showInfo} onChange={toggle_showInfo} />
      </div>

      {textSortedByRating.map((text, index) => {
        const beeCoins = text.bets.filter((bet) => bet.coin === 'beecoin').reduce((acc, bet) => acc + bet.amount, 0)
        const watermelonCoins = text.bets.filter((bet) => bet.coin === 'watermeloncoin').reduce((acc, bet) => acc + bet.amount, 0)
        const extra_like = extraRatingByCriteria(text.extraVotes.like)
        const extra_theme = extraRatingByCriteria(text.extraVotes.theme)
        const extra_readable = extraRatingByCriteria(text.extraVotes.readable)
        const extra_style = extraRatingByCriteria(text.extraVotes.style)
        const guessPercent = text.guesses.total > 0 ? Math.ceil((text.guesses.correct / text.guesses.total) * 100) : 0
        const guessFromPercent = text.guessesFrom.total > 0 ? Math.ceil((text.guessesFrom.correct / text.guessesFrom.total) * 100) : 0
        const activitiesDoneTotal = text.done.activities

        return (
          <div
            key={index}
            className={cn(style.topLine, {
              [style.mine]: text.isMine,
            })}
          >
            <div>
              {text.status === ActionType.AwaitEndVoting && '✅'}
              {text.status === ActionType.AwaitVoting && '⚠️'} #{index + 1}
            </div>
            <div>{pluralText(text.rating, pluralSets.balls)}</div>
            <div>{pluralText(text.characters, pluralSets.characters)}</div>
            <div>
              <a href={text.link} target="_blank" rel="noopener noreferrer">
                «{text.title}»
              </a>
            </div>
            <div>
              {text.nominationsWon.map((nomString, index) => {
                const nomItem = nominationsList.find((item) => item.type === nomString) as NominationItem
                return <NominationBlock key={index} count={1} extraSmallMode item={nomItem} />
              })}
            </div>
            <div>
              {showNickname && <span>{text.user}</span>}
              {showRealname && <span> | {text.realUser}</span>}
              {showTgid && <span> | {text.user_id}</span>}
            </div>
            <div>
              {showInfo && (
                <>
                  <div>
                    Угадал: {guessFromPercent}% ({text.guessesFrom.correct}/{text.guessesFrom.total})
                  </div>
                  <div>
                    Угадан: {guessPercent}% ({text.guesses.correct}/{text.guesses.total})
                  </div>
                  <div>
                    Общее: {extra_like} | Тема: {extra_theme} | Читабельность: {extra_readable} | Красота: {extra_style}
                  </div>
                  <div>Номинации получены: {text.nominations.length} ⭐️</div>
                  <div>
                    Поставлено 🐝{beeCoins} и 🍉{watermelonCoins}
                  </div>
                  <div>Рецензий написано: {text.done.reviews} ✉️</div>
                  <div>Рецензий написано (длина): {text.done.reviewsLength} ✉️</div>
                  <div>Рецензий получено: {text.reviewsTexts.length} ✉️</div>
                  <div>Активностей всего: {activitiesDoneTotal}</div>
                </>
              )}
            </div>
          </div>
        )
      })}

      <br />
      <br />
      <h2>Не разыгранные номинации</h2>
      <NominationsList nominations={nominationsIdle} nominationsWon={[]} />
    </div>
  )
}
