import React from 'react'
import style from './Reader.module.scss'
import { useOnload } from '../../../hooks/useOnload'
import { getTextForLibraryApi, getTextForReaderApi } from '../../../api/editor.api'
import { useParams } from 'react-router-dom'
import { pluralSets, pluralText } from './../../../methods/text.methods'
import { LoadingModal } from '../user/Loading'

interface Props {
  mode: 'reader' | 'library'
}

export const Reader = (props: Props) => {
  let { textguid = '' } = useParams()

  const [title, set_title] = React.useState('')
  const [characters, set_characters] = React.useState(0)
  const [textHTML, set_textHTML] = React.useState('')

  useOnload(() => {
    if (props.mode === 'reader') {
      getTextForReaderApi(textguid)
        .then((response) => {
          const editorText = response.data
          set_title(editorText.title)
          set_characters(editorText.characters)
          set_textHTML(editorText.text)
        })
        .catch(() => {
          console.error('Не удалось загрузить пользователь по уникальному id')
        })
    }
    if (props.mode === 'library') {
      getTextForLibraryApi(textguid)
        .then((response) => {
          const editorText = response.data
          set_title(editorText.title)
          set_characters(editorText.characters)
          set_textHTML(editorText.text)
        })
        .catch(() => {
          console.error('Не удалось загрузить пользователь по уникальному id')
        })
    }
  })

  return (
    <>
      <div className={style.reader}>
        <div className={style.title}>{title}</div>
        <div className={style.characters}>{pluralText(characters, pluralSets.characters)}</div>
        <div className={style.text} dangerouslySetInnerHTML={{ __html: textHTML }} />
      </div>
      <LoadingModal isLoading={characters === 0} />
    </>
  )
}
