import React from 'react'
import { ExtraVote, ProfileState } from '../../store/profile.slice'
import { ActionType, Text } from '../../types/types'
import { TextExtraVote } from './TextExtraVote'
import { postExtraVoteClear } from '../../api/user.api'
import { useParams } from 'react-router-dom'
import { getProfileMethod } from '../../methods/app.methods'
import { pluralSets, pluralText } from '../../methods/text.methods'

interface Props {
  text: Text
  profile: ProfileState
  index: number
  // shiftedIndex?: number // Для показа номера как в телеграме
  badges?: ExtraVote[]
  canExtraVoting?: boolean
  onChangeIndexCallback?: (textIndex: number) => void
}

const badgesTypes = {
  like: {
    1: 'В целом текст не понравился',
    2: 'В целом текст средний',
    3: 'В целом текст понравился',
  },
  theme: {
    1: 'Тема раскрыта плохо',
    2: 'Тема раскрыта средне',
    3: 'Тема раскрыта хорошо',
  },
  readable: {
    1: 'Текст читается тяжело',
    2: 'Текст читается средне',
    3: 'Текст читается хорошо',
  },
  style: {
    1: 'Стиль текста не понравился',
    2: 'Стиль текста обычный',
    3: 'Стиль текста приятный',
  },
  choose: {
    1: '1 балл',
    2: '2 балла',
    3: '3 балла',
  },
}

const Badge = ({ vote }: { vote: ExtraVote }) => {
  let color = ''
  let badgeText = ''
  if (vote.value === 1) color = 'red'
  if (vote.value === 2) color = 'yellow'
  if (vote.value === 3) color = 'green'
  badgeText = badgesTypes[vote.type][vote.value]
  return <span className={`badge ${color}`}>{badgeText}</span>
}

export const TextBlock = (props: Props) => {
  const [showExtraVote, set_showExtraVote] = React.useState(false)
  const text = props.text
  let { guid = '' } = useParams()

  function clearExtraVotesClick() {
    postExtraVoteClear({
      guid,
      textIndex: props.index,
    }).then(() => {
      getProfileMethod(guid)
    })
  }

  function onClickReviewButton() {
    if (props.onChangeIndexCallback) {
      props.onChangeIndexCallback(props.index - 1)
    }
  }

  const mrlk = props.badges?.length === 4 ? ((props.badges.reduce((acc, badge) => acc + badge.value, 0) / 12) * 100).toFixed(0) : 0

  return (
    <div className="textBlock">
      <div className="texttitle">
        {props.index}.{' '}
        <a href={text.link} target="_blank" rel="noopener noreferrer">
          {text.title}
        </a>
      </div>
      <div>
        {pluralText(text.characters, pluralSets.characters)} {text.characters > 10000 ? '🐳' : ''}
      </div>
      <div>
        {pluralText(text.reviews.total, pluralSets.reviews)}
        {text.reviews.my > 0 && <span>, из них {pluralText(text.reviews.my, pluralSets.reviews)} от тебя</span>}
      </div>

      {props.badges && (
        <div className="badges">
          {text.isMine && <span className="badge purple">Это твой текст</span>}
          {props.badges.map((vote, index) => {
            return <Badge vote={vote} key={index} />
          })}
        </div>
      )}
      {mrlk !== 0 && <div>Средняя оценка: {mrlk}%</div>}

      {showExtraVote && <TextExtraVote text={props.text} index={props.index} userExtraVotes={props.profile.extraVotes} />}

      <div className="buttons">
        {props.profile.status !== ActionType.GameEnded && props.canExtraVoting && !showExtraVote && !text.isMine && props.badges?.length !== 4 && (
          <button className="textButton" onClick={() => set_showExtraVote(true)}>
            Выставить дополнительные оценки
          </button>
        )}
        {props.profile.status !== ActionType.GameEnded && props.canExtraVoting && !text.isMine && props.badges && props.badges?.length > 0 && (
          <button className="textButton" onClick={clearExtraVotesClick}>
            Стереть оценки
          </button>
        )}
        {!!props.onChangeIndexCallback && !text.isMine && (
          <button className="textButton" onClick={onClickReviewButton}>
            Написать рецензию
          </button>
        )}
      </div>
    </div>
  )
}
