import { fameUsers } from '../../../data/users'
import { ProfileState } from '../../../store/profile.slice'
import { ResultsState } from '../../../store/results.slice'
import { Tab } from '../../ui/Tab'
import { Tabs } from '../../ui/Tabs'
import { pluralSets, pluralText } from './../../../methods/text.methods'
import { AchievementsList } from './components/AchievementsList'
import { ExtraVoteBlock } from './components/ExtraVoteBlock'
import { NominationsList } from './components/NominationsList'
import { Ufo } from './components/Ufo'
import style from './Results.module.scss'

interface Props {
  results: ResultsState
  profile: ProfileState
}

export const MyResults = (props: Props) => {
  function guessPercent(text: ResultsState['texts'][0]) {
    return text.guesses.total > 0 ? Math.ceil((text.guesses.correct / text.guesses.total) * 100) : 0
  }
  function guessFromPercent(text: ResultsState['texts'][0]) {
    return text.guessesFrom.total > 0 ? Math.ceil((text.guessesFrom.correct / text.guessesFrom.total) * 100) : 0
  }

  if (!props.results) {
    return null
  }

  const tabs = props.results.texts.map((text, index) => ({ name: `text_${index}`, title: text.title }))

  let incomingAchievements: string[] = []
  const fameUser = fameUsers.find((_fameUser) => _fameUser.name === props.profile.realName)
  if (fameUser && fameUser.incomingAchievements) {
    incomingAchievements = fameUser.incomingAchievements
  }

  return (
    <>
      <Tabs tabs={tabs}>
        {tabs.map((tab, index) => {
          const text = props.results.texts[index]
          const guessesNamesForTextList = Object.entries(text.guessesNamesIdentity).map(([name, count]) => {
            return {
              name,
              count,
              percent: ((count / text.guesses.total) * 100).toFixed(0),
            }
          })
          guessesNamesForTextList.sort(function (a, b) {
            if (a.count < b.count) return 1
            if (a.count > b.count) return -1
            return 0
          })
          return (
            <Tab name={tab.name}>
              <div className={style.results}>
                <div>рассказ</div>
                <h2>«{text.title}»</h2>
                <div>набрал</div>
                <h2>{pluralText(text.rating, pluralSets.balls)}</h2>
                <div>и занял</div>
                {text.rating > 0 && <h2>{text.myPlace} место</h2>}
                {text.rating === 0 && <h2>Место на кладбище 💀</h2>}

                {text.myPlace === 1 && <div className={style.medal}>🥇</div>}
                {text.myPlace === 2 && <div className={style.medal}>🥈</div>}
                {text.myPlace === 3 && <div className={style.medal}>🥉</div>}

                <div className={style.separator} />
                {text.votesTotal > 0 && (
                  <div>
                    За него {pluralText(text.votesTotal, pluralSets.voteVerb, true)} {pluralText(text.votesTotal, pluralSets.people)}
                  </div>
                )}
                <div className={style.separator} />
                <div>получил {pluralText(text.extraVotesTotal, pluralSets.extraVotesGen)}</div>
                <div className={style.separator} />
                <div>{pluralText(text.nominations.length, pluralSets.nominations)}</div>
                <div className={style.separator} />
                <div>{pluralText(text.reviews.length, pluralSets.reviews)}</div>

                <div className={style.separator} />

                <div>его длина — {pluralText(text.characters, pluralSets.characters)} </div>
                <div className={style.separator} />
                <div>он длиннее {pluralText(text.textsShorterMine, pluralSets.textsGen)} </div>
                <div className={style.separator} />
                <div>и короче {pluralText(text.textsLongerMine, pluralSets.textsGen)} </div>

                <div className={style.separator} />
                <div className={style.separator} />
                <div className={style.separator} />

                <div className={style.statsGrid4}>
                  <ExtraVoteBlock type="like" extraVotes={text.extraVotes} place={text.extraPlace.like} />
                  <ExtraVoteBlock type="theme" extraVotes={text.extraVotes} place={text.extraPlace.theme} />
                  <ExtraVoteBlock type="readable" extraVotes={text.extraVotes} place={text.extraPlace.readable} />
                  <ExtraVoteBlock type="style" extraVotes={text.extraVotes} place={text.extraPlace.style} />
                </div>

                <div className={style.separator} />

                {text.nominations.length > 0 && (
                  <>
                    <h2>{pluralText(text.nominations.length, pluralSets.nominations)}</h2>
                    <NominationsList nominations={text.nominations} nominationsWon={text.nominationsWon} />
                  </>
                )}

                {incomingAchievements.length && (
                  <>
                    <h2>{pluralText(incomingAchievements.length, pluralSets.achievements)}</h2>
                    <AchievementsList achievements={incomingAchievements} />
                  </>
                )}

                <div className={style.separator} />

                {text.reviews.length > 0 && (
                  <>
                    <div className={style.reviews}>
                      <h2>{pluralText(text.reviews.length, pluralSets.reviews)}</h2>
                      {text.reviews.map((review, index) => {
                        const rows = review.text.split('\n')
                        return (
                          <div className={style.review} key={index}>
                            {rows.map((row, index2) => {
                              return (
                                <div className={style.reviewRow} key={index2}>
                                  {row}
                                </div>
                              )
                            })}
                          </div>
                        )
                      })}
                    </div>
                  </>
                )}

                <div className={style.separator} />
                <div className={style.separator} />

                {text.guessesFrom.total > 0 && (
                  <h3>
                    Ты угадал(а) {text.guessesFrom.correct} из {text.guessesFrom.total} авторов ({guessFromPercent(text)}%)
                  </h3>
                )}
                <h3>
                  Его авторство угадало {text.guesses.correct} из {text.guesses.total} человек ({guessPercent(text)}%)
                </h3>
                <h3>Твоя идентичность состоит из:</h3>
                <div className={style.identity}>
                  <table>
                    {guessesNamesForTextList.map((guess, index) => {
                      return (
                        <tr key={index}>
                          <td>{guess.name}</td>
                          <td>{guess.count}</td>
                          <td>{guess.percent}%</td>
                        </tr>
                      )
                    })}
                  </table>
                </div>

                <div className={style.separator} />
                <div className={style.separator} />
                <div className={style.separator} />
                <div className={style.separator} />

                <h3>На твой текст поставили</h3>
                <div className={style.betsForMe}>
                  <div>🐝{text.betsToMe.beecoin}</div>
                  <div>🍉{text.betsToMe.watermeloncoin}</div>
                </div>
                {text.myPlace < 4 && (text.betsToMe.beecoin > 0 || text.betsToMe.watermeloncoin > 0) && <h2>И не зря!</h2>}
                {text.myPlace < 4 && text.betsToMe.beecoin === 0 && text.betsToMe.watermeloncoin === 0 && <h2>А зря!</h2>}

                <div className={style.separator} />
                <div className={style.separator} />

                {text.betsFromMe.length > 0 &&
                  text.betsFromMe.map((bet, index) => {
                    const pluralType = bet.coin === 'beecoin' ? pluralSets.beecoin : pluralSets.watermeloncoin
                    const coinIcon = bet.coin === 'beecoin' ? '🐝' : '🍉'
                    if (bet.factor > 0) {
                      const winCount = bet.amount * bet.factor
                      return (
                        <div className={style.bets} key={index}>
                          Твоя ставка {coinIcon}
                          {bet.amount} на текст <span className={style.strong}>«{bet.textTitle}»</span> сработала ✕{bet.factor} и принесла тебе {coinIcon}
                          {pluralText(winCount, pluralType)}
                        </div>
                      )
                    } else {
                      return (
                        <div className={style.bets} key={index}>
                          Твоя ставка на текст <span className={style.strong}>«{bet.textTitle}»</span> не сработала 😭
                        </div>
                      )
                    }
                  })}

                <div className={style.separator} />
                <div className={style.separator} />
                <div className={style.separator} />
                <div className={style.separator} />
                <div className={style.separator} />

                <h2>💛 Спасибо за участие 💛</h2>
                {/* <Ufo
                  secret_type="ufo3"
                  wonText="Инопланетяне благодарят тебя за оказанное внимание!"
                  errorText="Инопланетяне уже отдали тебе всё, что у них было, сжалься над ними!"
                  isDoneFlag={props.profile.secrets.ufo3}
                /> */}
              </div>
            </Tab>
          )
        })}
      </Tabs>
    </>
  )
}
