// Выбор темы
export const date_theme_start = new Date('March 8, 2025 00:00:00')
export const date_theme_end = new Date('March 9, 2025 23:59:59')

// Написание текстов
export const date_texts_start = new Date('March 10, 2025 00:00:00')
export const date_texts_end = new Date('March 19, 2025 23:59:59')

// Голосование
export const date_voting_start = new Date('March 20, 2025 00:00:00')
export const date_voting_end = new Date('March 24, 2025 23:59:59')

// Результаты
export const date_results = new Date('March 25, 2025 00:00:00')
