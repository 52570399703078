import style from './Kot.module.scss'
import cn from 'classnames'

interface Props {
  percent: number
}

export const Kot = (props: Props) => {
  const percentToWin = 75
  const blinsPercent = (props.percent / percentToWin) * 100
  const shift = blinsPercent <= 100 ? blinsPercent * 2 : 200

  return (
    <div className={style.kot}>
      <div className={style.wrapper}>
        <img src="/img/kot/kot_empty.png" alt="kot full" />
        <img
          src="/img/kot/kot_full.png"
          alt="kot full"
          style={{
            top: -shift,
            height: shift,
            marginBottom: -shift,
          }}
        />
      </div>
    </div>
  )
}
