import { ExtraVote, ExtraVoteValue, ProfileState } from '../../../store/profile.slice'
import { TextBlock } from '../../texts/TextBlock'
import { ActionType, Text } from '../../../types/types'
import { nominationsList } from '../../../data/nominations'
import { AchievementsList } from './components/AchievementsList'
import style from './Profile.module.scss'
import { NominationsList } from './components/NominationsList'
import cn from 'classnames'
import { GrelkaState } from '../../../store/store'
import { getPlayerChart } from './Profile.chart.generator'
import { Bar } from 'react-chartjs-2'
import { pluralSets, pluralText } from '../../../methods/text.methods'
import { Link, useNavigate, useParams } from 'react-router-dom'

interface Props {
  texts: Text[]
  profile: ProfileState
  library: GrelkaState['library']
}

export const Profile = (props: Props) => {
  let { guid = '' } = useParams()
  const navigate = useNavigate()

  const badgesCount = {
    like: props.profile.extraVotes.filter((vote) => vote.type === 'like').length,
    theme: props.profile.extraVotes.filter((vote) => vote.type === 'theme').length,
    readable: props.profile.extraVotes.filter((vote) => vote.type === 'readable').length,
    style: props.profile.extraVotes.filter((vote) => vote.type === 'style').length,
  }

  const meInLibrary = props.library.players.find((player) => player.name == props.profile.realName)

  // Нужно
  // Проголосовать за все тексты
  // Поставить 90% дополнительных оценок
  // Можно не ставить 3 номинации
  // Поставить в угадайке хотя бы половину текстов
  // Сделать хотя бы 2 ставки

  const textsCountNotMine = props.texts.filter((text) => !text.isMine).length

  const needExtraVotes = Math.floor(textsCountNotMine * 4 * 0.9)
  const needNominations = Math.floor(nominationsList.length - 3)
  const needBets = 2
  const needGuesses = Math.floor(textsCountNotMine / 2)
  const needVotes = 5
  const needReviews = textsCountNotMine

  const extraVotesDone = badgesCount.like + badgesCount.theme + badgesCount.readable + badgesCount.style
  const isExtraVoted = extraVotesDone >= needExtraVotes

  const isNominated = props.profile.nominations.length >= needNominations
  const isBetted = props.profile.bets.length >= needBets
  const isGuessed = props.profile.guesses.length >= needGuesses
  const isVoted = props.profile.votes.length === needVotes
  const myReviews = props.texts.filter((text) => text.reviews.my > 0).length

  const altText = 'Альтернативный текст для сео бла бла бла зачем ты читаешь код сайта тебе что нечего делать'

  const myLibraryTexts = props.library.texts.filter((text) => text.user_name === props.profile.realName)
  const playerChart = getPlayerChart(myLibraryTexts)

  interface StatBlockProps {
    title: string
    instruction: string
    valueDone: number
    valueNeed: number
    valueTotal?: number
  }
  const StatBlock = (props: StatBlockProps) => {
    return (
      <div
        className={cn(style.statBlock, {
          [style.yellow]: props.valueDone >= props.valueNeed,
          [style.green]: props.valueTotal ? props.valueDone >= props.valueTotal : props.valueDone >= props.valueNeed,
        })}
      >
        <div className={style.statBlockTitle}>{props.title}</div>
        <div className={style.statBlockGrid}>
          <div className={style.statBlockValue}>
            <div className={style.statBlockValueTitle}>Сделано</div>
            <div className={style.statBlockValueNumber}>{props.valueDone}</div>
          </div>
          <div className={style.statBlockValue}>
            <div className={style.statBlockValueTitle}>Нужно</div>
            <div className={style.statBlockValueNumber}>{props.valueNeed}</div>
          </div>
          <div className={style.statBlockValue}>
            <div className={style.statBlockValueTitle}>Всего</div>
            <div className={style.statBlockValueNumber}>{props.valueTotal || '???'}</div>
          </div>
        </div>
        <div className={style.instruction}>{props.instruction}</div>
      </div>
    )
  }

  const AWAIT_START = [ActionType.AwaitText, ActionType.AwaitStartVoting].includes(props.profile.status)
  const IN_GAME = ![ActionType.Greetings, ActionType.AwaitName, ActionType.AwaitText, ActionType.AwaitStartVoting, ActionType.GameEnded].includes(
    props.profile.status
  )
  // const GAME_ENDED = props.profile.status === ActionType.GameEnded
  const BANNED = props.profile.status === ActionType.Banned

  function goToEditor() {
    navigate(`/editor/${guid}`)
  }

  return (
    <>
      <div className={style.bio}>
        <div className={style.hint}>
          {props.profile.status === ActionType.AwaitVoting && <div>Время читать тексты и голосовать!</div>}
          {props.profile.status === ActionType.AwaitEndVoting && <div>Ждём, пока все проголосуют!</div>}
          {props.profile.status === ActionType.GameEnded && <div>Игра окончена! Спасибо за участие!</div>}
          {props.profile.status === ActionType.AwaitText && <div>Время писать и сдавать текст!</div>}
          {props.profile.status === ActionType.AwaitStartVoting && <div>Время ждать, пока остальные сдадут тексты!</div>}
          {props.profile.status === ActionType.Banned && <div>Всё кончено... или всё только начинается...</div>}
        </div>

        {!BANNED && AWAIT_START && (
          <div className={style.editorBlock}>
            <div className={style.editorHint}>Уже сдали {pluralText(props.texts.length, pluralSets.texts)}</div>
            <div className={style.editorHint}>Сдавать тексты можно через гугл-документы</div>
            <div className={style.editorHint}>
              и при помощи <Link to={`/editor/${guid}`}>нового редактора</Link>
            </div>
            <button onClick={goToEditor}>Перейти в редактор текстов</button>
          </div>
        )}

        {!BANNED && IN_GAME && (
          <div className={style.stages}>
            <table cellPadding={0} cellSpacing={0}>
              <tbody>
                <tr>
                  <td colSpan={3}>
                    <img src="/img/stages/base.png" alt={altText} />
                  </td>
                </tr>
                <tr>
                  <td>{isExtraVoted ? <img src="/img/stages/extra_1.png" alt={altText} /> : <img src="/img/stages/extra_0.png" alt={altText} />}</td>
                  <td>
                    <img src="/img/stages/text.png" alt={altText} />
                  </td>
                  <td>{isNominated ? <img src="/img/stages/nom_1.png" alt={altText} /> : <img src="/img/stages/nom_0.png" alt={altText} />}</td>
                </tr>
                <tr>
                  <td>{isBetted ? <img src="/img/stages/bets_1.png" alt={altText} /> : <img src="/img/stages/bets_0.png" alt={altText} />}</td>
                  <td>{isVoted ? <img src="/img/stages/vote_1.png" alt={altText} /> : <img src="/img/stages/vote_0.png" alt={altText} />}</td>
                  <td>{isGuessed ? <img src="/img/stages/guess_1.png" alt={altText} /> : <img src="/img/stages/guess_0.png" alt={altText} />}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {!BANNED && IN_GAME && (
          <div className={style.stats}>
            <StatBlock
              title="Голоса"
              valueDone={props.profile.votes.length}
              valueNeed={needVotes}
              valueTotal={needVotes}
              instruction={'Выбрать 5 текстов в телеграме'}
            />
            <StatBlock
              title="Оценки"
              valueDone={extraVotesDone}
              valueNeed={needExtraVotes}
              valueTotal={textsCountNotMine * 4}
              instruction={'Поставить оценки для 90% текстов'}
            />
            <StatBlock
              title="Номинации"
              valueDone={props.profile.nominations.length}
              valueNeed={needNominations}
              valueTotal={nominationsList.length}
              instruction={'Можно не выдавать 3 номинации'}
            />
            <StatBlock
              title="Угадайки"
              valueDone={props.profile.guesses.length}
              valueNeed={needGuesses}
              valueTotal={textsCountNotMine}
              instruction={'Попробовать угадать 50% игроков'}
            />
            <StatBlock title="Ставки" valueDone={props.profile.bets.length} valueNeed={needBets} instruction={'Сделать хотя бы 2 ставки'} />
            <StatBlock title="Рецензии" valueDone={myReviews} valueNeed={needReviews} instruction={'Написать каждому хотя бы одну рецензию'} />
          </div>
        )}

        {!BANNED && (
          <div className="profile_chart">
            <h3>Мой прогресс</h3>
            <div>Красным обозначаются экстра баллы, которые не идут в общий зачёт</div>
            <Bar data={playerChart.data} options={playerChart.options} id="chart" width={600} height={300} />
          </div>
        )}

        {!BANNED && meInLibrary && (
          <>
            {meInLibrary.achievements.length > 0 && (
              <>
                <h2>Мои ачивки</h2>
                <AchievementsList achievements={meInLibrary ? meInLibrary.achievements : []} />
              </>
            )}
            {meInLibrary.nominations.length > 0 && (
              <>
                <h2>Мои номинации</h2>
                <NominationsList nominations={meInLibrary ? meInLibrary.nominations : []} nominationsWon={[]} />
              </>
            )}
          </>
        )}

        {!BANNED && IN_GAME && props.profile.votes.length > 0 && (
          <>
            <h2>Выбранные тексты</h2>
            <div className="texts">
              {props.profile.votes.length > 0 &&
                props.profile.votes.map((vote, index) => {
                  if (!vote) return 'ERROR'
                  const text = props.texts.find((text) => text.link === vote.link)
                  const textNumber = props.texts.findIndex((text) => text.link === vote.link)
                  const badge: ExtraVote = {
                    type: 'choose',
                    value: vote.value as ExtraVoteValue,
                    textIndex: 1,
                  }
                  if (text && textNumber > -1) {
                    return <TextBlock text={text} profile={props.profile} index={textNumber + 1} key={index} badges={[badge]} />
                  }
                  return null
                })}
            </div>
          </>
        )}
      </div>
    </>
  )
}
