import { GrelkaState } from '../../../../store/store'
import { Tab } from '../../../ui/Tab'
import { Tabs } from '../../../ui/Tabs'
import { Library } from './library/Library'
import style from './HallOfFame.module.scss'
import { Rounds } from './Rounds'
import { PlayerAchievements } from './PlayerAchievements'
import { Games } from './Games'

interface Props {
  library: GrelkaState['library']
}

export const HallOfFame = (props: Props) => {
  const tabs = [
    { name: 'players', title: 'Игроки' },
    { name: 'games', title: 'Игры' },
    { name: 'achievements', title: 'Ачивки' },
    { name: 'library', title: 'Библиотека им. Грелкина' },
  ]

  return (
    <div className={style.hallOfFame}>
      <Tabs tabs={tabs}>
        <Tab name="rounds">
          <Rounds library={props.library} />
        </Tab>
        <Tab name="players">
          <Rounds library={props.library} />
        </Tab>
        <Tab name="games">
          <Games />
        </Tab>
        <Tab name="achievements">
          <PlayerAchievements library={props.library} />
        </Tab>
        <Tab name="library">
          <Library libraryTexts={props.library.texts} />
        </Tab>
      </Tabs>
    </div>
  )
}
