import style from './Library.module.scss'
import React from 'react'
import { RoundText } from '../../../admin/textsAdminTabs/adminControl/adminControl.types'
import { RoundTextComponent } from '../../../admin/textsAdminTabs/adminControl/RoundTextComponent'
import { compare, pluralSets } from '../../../../../methods/text.methods'
import { useDebounce } from '../../../../../hooks/useDebounce'
import cn from 'classnames'
import { pluralText } from '../../../../../methods/text.methods'

interface Props {
  libraryTexts: RoundText[]
  showFullStatistics?: boolean
}

export const Library = (props: Props) => {
  const [texts, set_texts] = React.useState<RoundText[]>([])
  const [textsFiltered, set_textsFiltered] = React.useState<RoundText[]>([])
  const [filter, set_filter] = React.useState('')
  const debouncedFilter = useDebounce(filter, 500)
  const [currentSort, set_currentSort] = React.useState<'title' | 'round' | 'score' | 'characters' | 'place'>('round')

  React.useEffect(() => {
    onFilterPlayers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilter])

  React.useEffect(() => {
    set_texts(props.libraryTexts)
    set_textsFiltered(props.libraryTexts)
  }, [props.libraryTexts])

  function onFilterPlayers() {
    const textTrimmed = filter.trim()

    const newTextsFiltered = texts.filter((text) => {
      if (!textTrimmed) return true
      const inTitle = compare(text.title, textTrimmed)
      const inAuthor = compare(text.user_name || '', textTrimmed)
      return inTitle || inAuthor
    })

    set_textsFiltered(newTextsFiltered)

    set_filter(filter)
  }

  function sortWith(newSort: 'title' | 'round' | 'score' | 'characters' | 'place') {
    const reverse = newSort === 'score' || newSort === 'characters'

    const textSorted = [...textsFiltered]
    textSorted.sort(function (a, b) {
      let criteriaA = a[newSort]
      let criteriaB = b[newSort]
      if (newSort === 'place' && (criteriaA === 0 || criteriaA === -1)) criteriaA = criteriaA + 1 * 100
      if (newSort === 'place' && (criteriaB === 0 || criteriaB === -1)) criteriaB = criteriaB + 1 * 100

      if (criteriaA < criteriaB) return reverse ? 1 : -1
      if (criteriaA > criteriaB) return reverse ? -1 : 1
      return 0
    })
    set_textsFiltered(textSorted)
    set_currentSort(newSort)
  }

  const textsTotal = texts.length
  const charactersTotal = texts.reduce((acc, text) => acc + text.characters, 0)
  const scoreTotal = texts.reduce((acc, text) => acc + text.score, 0)
  const winnersScore = texts.filter((text) => text.place === 1).reduce((acc, text) => acc + text.score, 0)
  const winnersScorePercent = Math.floor((winnersScore / scoreTotal) * 100)
  const deadTexts = texts.filter((text) => text.score === 0).length
  const deadTextsPercent = Math.floor((deadTexts / textsTotal) * 100)

  return (
    <div className={style.control}>
      <div className={style.library}>
        <div className={style.header}>
          <div>
            <img src="/img/book.jpg" alt="book" />
            <img src="/img/book.jpg" alt="book" />
            <img src="/img/book.jpg" alt="book" />
          </div>
          <div>
            <h2>Библиотека им. Грелкина</h2>
            <div>В нашей библиотеке {pluralText(texts.length, pluralSets.texts)}!</div>
          </div>
          <div>
            <img src="/img/book.jpg" alt="book" />
            <img src="/img/book.jpg" alt="book" />
            <img src="/img/book.jpg" alt="book" />
          </div>
        </div>
        <div className={style.controls}>
          <div>Поиск</div>
          <input type="text" value={filter} onChange={(event) => set_filter(event.target.value)} />
        </div>

        <div className={style.buttons}>
          <button onClick={() => sortWith('title')} className={cn({ [style.selected]: currentSort === 'title' })}>
            По названию
          </button>
          <button onClick={() => sortWith('round')} className={cn({ [style.selected]: currentSort === 'round' })}>
            По раунду
          </button>
          <button onClick={() => sortWith('place')} className={cn({ [style.selected]: currentSort === 'place' })}>
            По месту
          </button>
          <button onClick={() => sortWith('score')} className={cn({ [style.selected]: currentSort === 'score' })}>
            По баллам
          </button>
          <button onClick={() => sortWith('characters')} className={cn({ [style.selected]: currentSort === 'characters' })}>
            По длине
          </button>
        </div>

        {texts.length > 0 && (
          <div className={style.texts}>
            {textsFiltered.map((text, index) => {
              return <RoundTextComponent text={text} key={index} showName />
            })}
          </div>
        )}

        <div className={style.statistics}>
          <div className={style.totalBlock}>
            <h3>Всего текстов</h3>
            <div className={style.icon}>📔</div>
            <div className={style.count}>{textsTotal}</div>
            <div className={style.countSmall}></div>
          </div>

          <div className={style.totalBlock}>
            <h3>Всего знаков</h3>
            <div className={style.icon}>🐭</div>
            <div className={style.count}>{charactersTotal}</div>
            <div className={style.countSmall}></div>
          </div>

          <div className={style.totalBlock}>
            <h3>Мёртвые тексты</h3>
            <div className={style.icon}>💀</div>
            <div className={style.count}>{deadTexts}</div>
            <div className={style.countSmall}>{deadTextsPercent}%</div>
          </div>

          {props.showFullStatistics && (
            <>
              <div className={style.totalBlock}>
                <h3>Всего баллов</h3>
                <div className={style.icon}>⭐️</div>
                <div className={style.count}>{scoreTotal}</div>
                <div className={style.countSmall}></div>
              </div>

              <div className={style.totalBlock}>
                <h3>Баллов у победителей</h3>
                <div className={style.icon}>👑</div>
                <div className={style.count}>{winnersScore}</div>
                <div className={style.countSmall}>{winnersScorePercent}%</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
