import { pluralSets, pluralText } from '../../../../methods/text.methods'
import style from './HallOfFame.module.scss'

interface Props {}

export const Games = (props: Props) => {
  const roundThemes = [
    {
      round: 1,
      themes: [
        {
          title: 'Незабываемый поход в магазин',
          chosen: false,
        },
        {
          title: 'Потери и находки',
          chosen: false,
        },
        {
          title: 'Изменения',
          chosen: true,
        },
      ],
      players: 15,
      texts: 15,
    },
    {
      round: 2,
      themes: [
        {
          title: 'Случайность',
          chosen: false,
        },
        {
          title: 'Цена желания',
          chosen: true,
        },
        {
          title: 'Лучше поздно чем никогда',
          chosen: false,
        },
      ],
      players: 12,
      texts: 12,
    },
    {
      round: 3,
      themes: [
        {
          title: 'Две стороны одной монеты',
          chosen: false,
        },
        {
          title: 'На краю',
          chosen: false,
        },
        {
          title: 'Сопротивление',
          chosen: true,
        },
      ],
      players: 22,
      texts: 23,
    },
    {
      round: 4,
      themes: [
        {
          title: 'Случайность',
          chosen: false,
        },
        {
          title: 'Две стороны одной монеты',
          chosen: true,
        },
        {
          title: 'Всего мгновение',
          chosen: false,
        },
      ],
      players: 18,
      texts: 18,
    },
    {
      round: 5,
      themes: [
        {
          title: 'Возвращение',
          chosen: true,
        },
        {
          title: 'Лёгкость',
          chosen: false,
        },
        {
          title: 'Память',
          chosen: false,
        },
      ],
      players: 23,
      texts: 25,
    },
    {
      round: 6,
      themes: [
        {
          title: 'Это моя работа',
          chosen: true,
        },
        {
          title: 'Отвечать за слова',
          chosen: false,
        },
        {
          title: 'Ядовитые плоды',
          chosen: false,
        },
      ],
      players: 26,
      texts: 31,
    },
    {
      round: 7,
      themes: [
        {
          title: 'Ядовитые плоды',
          chosen: true,
        },
        {
          title: 'На краю',
          chosen: false,
        },
        {
          title: 'Разделение',
          chosen: false,
        },
      ],
      players: 22,
      texts: 37,
    },
  ]

  return (
    <>
      <div className={style.themes}>
        {roundThemes.map((round, index) => {
          return (
            <div className={style.round} key={index}>
              <div className={style.roundNumber}>{round.round}</div>
              <div className={style.themes}>
                {round.themes.map((theme, index2) => {
                  return (
                    <div key={index2} className={style.theme}>
                      {theme.chosen && '✅'}
                      {!theme.chosen && '❌'}

                      {theme.title}
                    </div>
                  )
                })}
              </div>
              <div className={style.players}>
                <div>
                  <div className={style.big}>{round.players}</div>
                  <div>{pluralText(round.players, pluralSets.players, true)}</div>
                </div>
                <div>
                  <div className={style.big}>{round.texts}</div>
                  <div>{pluralText(round.players, pluralSets.texts, true)}</div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
