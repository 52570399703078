import React from 'react'

/** Debounce hook
 * @param {any} target Объект, изменения которого будут отслеживаться
 * @param {number} delay Задержка дебаунса
 */
export const useDebounce = (
  target: any,
  delay: number
  //  skipHooksCount?: number
) => {
  const [deboucedValue, setDebouncedValue] = React.useState(target)
  // const [skipHooks, setSkipHooks] = React.useState(skipHooksCount);

  // const preventInitDebounce = React.useRef(false);
  React.useEffect(() => {
    // if (skipHooksCount && skipHooksCount > 0) {
    //   setSkipHooks(skipHooksCount - 1);
    //   console.log('### HOOK: skipped, Remain:', skipHooksCount);
    //   return;
    // }
    // if (!preventInitDebounce.current) {
    //   console.log('### HOOK: prevented init debounce');
    //   preventInitDebounce.current = true;
    //   return;
    // }

    // console.log('### HOOK: debounce');
    const handler = setTimeout(() => {
      setDebouncedValue(target)
    }, delay)
    return () => {
      clearInterval(handler)
    }
  }, [target, delay])

  return deboucedValue
}
