import style from './AddTextModal.module.scss'
import { useParams } from 'react-router-dom'
import { sendTextApi } from '../../../../../api/adminControl.api'
import React from 'react'
import { getPlayersAdminMethod } from '../../../../../methods/app.methods'

interface Props {
  userName: string
  callbackClose: () => void
}

export const AddTextModal = (props: Props) => {
  let { guid = '' } = useParams()

  const [title, set_title] = React.useState<string>('')
  const [link, set_link] = React.useState<string>('')
  const [round, set_round] = React.useState<number>(0)
  const [characters, set_characters] = React.useState<number>(0)
  const [place, set_place] = React.useState<number>(0)
  const [score, set_score] = React.useState<number>(0)

  function sendText() {
    const dataToSend = {
      guid,
      user_name: props.userName,
      title,
      link,
      round,
      characters,
      place,
      score,
    }
    sendTextApi(dataToSend)
      .then(() => {
        getPlayersAdminMethod(guid)
        props.callbackClose()
      })
      .catch(() => alert('О нет, что-то сломалось!'))
  }

  function goBack() {
    props.callbackClose()
  }

  return (
    <div className={style.modal}>
      <div className={style.form}>
        <h2>Добавить текст</h2>

        <div className={style.formFields}>
          <div className={style.formField}>
            <div>Имя</div>
            <div>{props.userName}</div>
          </div>

          <div className={style.formField}>
            <div>Название *</div>
            <div>
              <input type="text" value={title} onChange={(event) => set_title(event.target.value)} />
            </div>
          </div>

          <div className={style.formField}>
            <div>Ссылка *</div>
            <div>
              <input type="text" value={link} onChange={(event) => set_link(event.target.value)} />
            </div>
          </div>

          <div className={style.formField}>
            <div>Раунд *</div>
            <div>
              <input type="number" value={round} onChange={(event) => set_round(parseInt(event.target.value))} />
            </div>
          </div>

          <div className={style.formField}>
            <div>Число знаков *</div>
            <div>
              <input type="number" value={characters} onChange={(event) => set_characters(parseInt(event.target.value))} />
            </div>
          </div>

          <div className={style.formField}>
            <div>Место:</div>
            <div>
              <input type="number" value={place} onChange={(event) => set_place(parseInt(event.target.value))} />
            </div>
          </div>

          <div className={style.formField}>
            <div>Баллы:</div>
            <div>
              <input type="number" value={score} onChange={(event) => set_score(parseInt(event.target.value))} />
            </div>
          </div>
        </div>

        <button onClick={sendText}>Добавить!</button>
        <button onClick={goBack}>Назад</button>
      </div>
    </div>
  )
}
