import style from './Review.module.scss'
import { GrelkaState } from '../../../store/store'
import { Tabs } from '../../ui/Tabs'
import { Tab } from '../../ui/Tab'
import { ArchiveReview } from '../../../types/types'

interface Props {
  review: ArchiveReview
  isMine: boolean
}

export const ReviewComponent = (props: Props) => {
  const rows = props.review.text.split('\n')
  return (
    <div className={style.review}>
      {!props.isMine && <div className={style.header}>Это рецензия на твой текст «{props.review.texttitle}»</div>}
      {props.isMine && <div className={style.header}>Твоя рецензия на текст «{props.review.texttitle}»</div>}
      <div className={style.reviewBody}>
        {rows.map((row, index2) => {
          return (
            <div className={style.reviewRow} key={index2}>
              {row}
            </div>
          )
        })}
      </div>
    </div>
  )
}
