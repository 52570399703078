import { getAdminPlayers } from '../api/adminControl.api'
import {
  getBetsByGuidApi,
  getGameByGuidApi,
  getLibrary,
  getProfileByGuidApi,
  getResultsByGuidApi,
  getTextsAdminByGuidApi,
  getTextsByGuidApi,
  getUsersByGuidApi,
  getVotesByGuidApi,
} from '../api/user.api'
import { adminSlice } from '../store/admin.slice'
import { betsSlice } from '../store/bets.slice'
import { gameSlice } from '../store/game.slice'
import { librarySlice } from '../store/library.slice'
import { profileSlice } from '../store/profile.slice'
import { resultsSlice } from '../store/results.slice'
import { grelkaStore } from '../store/store'
import { textsSlice } from '../store/texts.slice'
import { uiSlice } from '../store/ui.slice'

export function getProfileMethod(guid: string) {
  grelkaStore.dispatch(uiSlice.actions.setLoding(true))
  getProfileByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(profileSlice.actions.setProfile(res.data))
      setTimeout(() => {
        grelkaStore.dispatch(uiSlice.actions.setLoding(false))
      }, 500)
    })
    .catch(() => {
      // alert('Что-то пошло не так! Тыкайте админа!')
      // window.location.href = '/'
    })
}

export function getTextsMethod(guid: string) {
  getTextsByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(textsSlice.actions.setList(res.data))
    })
    .catch(() => {
      // alert('Что-то пошло не так! Тыкайте админа!')
      // window.location.href = '/'
    })
}

export function getGameMethod(guid: string) {
  getGameByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(gameSlice.actions.setPlayers(res.data))
    })
    .catch(() => {
      // alert('Что-то пошло не так! Тыкайте админа!')
      // window.location.href = '/'
    })
}

export function getResultsMethod(guid: string) {
  getResultsByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(resultsSlice.actions.setResults(res.data))
    })
    .catch(() => {
      console.log(':)')
    })
}

export function getVotesAdminMethod(guid: string) {
  getVotesByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(adminSlice.actions.setVotes(res.data))
    })
    .catch((error) => {
      console.log(':)')
    })
}

export function getUsersAdminMethod(guid: string) {
  getUsersByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(adminSlice.actions.setUsers(res.data))
    })
    .catch((error) => {
      console.log(':)')
    })
}

export function getTextsAdminMethod(guid: string) {
  getTextsAdminByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(adminSlice.actions.setTextsAdmin(res.data))
    })
    .catch((error) => {
      console.log(':)')
    })
}

export function getBetsMethod(guid: string) {
  getBetsByGuidApi(guid)
    .then((res) => {
      grelkaStore.dispatch(betsSlice.actions.setBetsResults(res.data))
    })
    .catch((error) => {
      console.log(':)')
    })
}

export function getPlayersAdminMethod(guid: string) {
  getAdminPlayers(guid)
    .then((res) => {
      grelkaStore.dispatch(adminSlice.actions.setPlayers(res.data))
    })
    .catch((error) => {
      console.log(':)')
    })
}

export function getPlayerLibrary(guid: string) {
  getLibrary(guid)
    .then((res) => {
      grelkaStore.dispatch(librarySlice.actions.setLibrary(res.data))
    })
    .catch((error) => {
      console.log(':)')
    })
}
