import { RoundText } from '../admin/textsAdminTabs/adminControl/adminControl.types'

export function getChartRoundScores(texts: RoundText[]) {
  const playerScoreBase = [1, 2, 3, 4, 5, 6, 7].map((round) => {
    const roundTexts = texts.filter((text) => text.round === round && text.extra !== 1)
    return roundTexts.reduce((acc, text) => {
      return acc + text.score
    }, 0)
  })
  const playerScoreExtra = [1, 2, 3, 4, 5, 6, 7].map((round) => {
    const roundTexts = texts.filter((text) => text.round === round && text.extra === 1)
    return roundTexts.reduce((acc, text) => acc + text.score, 0)
  })
  return {
    base: playerScoreBase,
    extra: playerScoreExtra,
  }
}

export function getPlayerChart(texts: RoundText[]) {
  const playerChartScore = getChartRoundScores(texts)
  return {
    type: 'bar',
    data: {
      labels: ['1', '2', '3', '4', '5', '6', '7'],
      datasets: [
        {
          label: 'Баллы',
          data: [...playerChartScore.base],
          borderWidth: 0,
          backgroundColor: 'limegreen',
        },
        {
          label: 'Экстра баллы',
          data: [...playerChartScore.extra],
          borderWidth: 0,
          backgroundColor: 'orangered',
        },
      ],
    },
    options: {
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true,
        },
      },
      // scales: {
      //   y: {
      //     beginAtZero: true,
      //   },
      // },
      plugins: {
        legend: {
          display: false,
        },
      },
    },
  }
}
