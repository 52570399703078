import style from './AdminTextGuesses.module.scss'
import { TextAdmin } from '../../../../types/types'

interface Props {
  textsAdmin: TextAdmin[]
}

export const AdminTextGuesses = (props: Props) => {
  const allNames: { [key: string]: number } = {}
  props.textsAdmin.forEach((text) => {
    const guessList = Object.entries(text.guessesNamesForText).map(([name, count]) => {
      return {
        name,
        count,
      }
    })
    guessList.forEach((guess) => {
      if (allNames[guess.name]) {
        allNames[guess.name] += 1
      } else {
        allNames[guess.name] = 1
      }
    })
  })
  const allNamesList = Object.entries(allNames).map(([name, count]) => {
    return {
      name,
      count,
    }
  })
  allNamesList.sort(function (a, b) {
    if (a.count < b.count) return 1
    if (a.count > b.count) return -1
    return 0
  })

  return (
    <div className={style.adminTextGuesses}>
      <h2>Угадайки</h2>

      <div className={style.namesTotal}>
        <table>
          {allNamesList.map((user) => {
            return (
              <tr>
                <td>{user.name}</td>
                <td>{user.count}</td>
              </tr>
            )
          })}
        </table>
      </div>

      <h2>Тексты</h2>

      <div className={style.list}>
        {props.textsAdmin.map((text, index) => {
          const totalGuesses = text.guesses.total
          const guessList = Object.entries(text.guessesNamesForText).map(([name, count]) => {
            return {
              name,
              count,
              percent: ((count / totalGuesses) * 100).toFixed(0),
            }
          })
          guessList.sort(function (a, b) {
            if (a.count < b.count) return 1
            if (a.count > b.count) return -1
            return 0
          })
          return (
            <div className={style.text} key={index}>
              <h3>{text.title}</h3>
              <div className={style.author}>Автор: {text.title == 'Превращение' ? 'Ликвидирован 💀' : text.realUser}</div>
              <table>
                {guessList.map((guess) => {
                  return (
                    <tr>
                      <td>{guess.name}</td>
                      <td>{guess.count}</td>
                      <td>{guess.percent}%</td>
                    </tr>
                  )
                })}
              </table>
            </div>
          )
        })}
      </div>
    </div>
  )
}
