import { AchievementsList } from '../components/AchievementsList'
import { NominationsList } from '../components/NominationsList'
import style from './HallOfFame.module.scss'
import { GrelkaState } from '../../../../store/store'
import { pluralSets, pluralText } from '../../../../methods/text.methods'

interface Props {
  library: GrelkaState['library']
}

export const PlayerAchievements = (props: Props) => {
  const playersSorted = [...props.library.players]
  playersSorted.sort(function (a, b) {
    const summA = a.achievements.length + a.nominations.length
    const summB = b.achievements.length + b.nominations.length
    if (summA > summB) return -1
    if (summA < summB) return 1
    return 0
  })

  return (
    <>
      <div className={style.achievemenets}>
        {playersSorted.map((player, index) => {
          return (
            <div className={style.player} key={index}>
              <h3>{player.name}</h3>
              <h4>{pluralText(player.achievements.length, pluralSets.achievements)}</h4>
              <div className={style.achievements}>
                {player.achievements.length > 0 && (
                  <>
                    <AchievementsList achievements={player.achievements} smallMode />
                  </>
                )}
              </div>

              <h4>{pluralText(player.nominations.length, pluralSets.nominations)}</h4>
              <div className={style.achievements}>
                {player.nominations.length > 0 && (
                  <>
                    <NominationsList nominations={player.nominations} nominationsWon={[]} smallMode />
                  </>
                )}
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}
