import { AxiosResponse } from 'axios'
import { axiosInstance } from './adapter'

export function getAdminPlayers(guid: string): Promise<AxiosResponse<any, any>> {
  return axiosInstance.get(`/control/players/${guid}`)
}

interface SendTextPayload {
  guid: string
  user_name: string
  title: string
  link: string
  round: number
  characters: number
  place: number
  score: number
}

export function sendTextApi(payload: SendTextPayload): Promise<AxiosResponse<any, any>> {
  return axiosInstance.post(`/control/texts`, payload)
}

interface TransferEditorTexts {
  transfered: number
}

export function transferEditorTextsApi(guid: string): Promise<AxiosResponse<TransferEditorTexts, any>> {
  return axiosInstance.get(`/service/transfer_editor_texts/${guid}`)
}

export function shuffleTextsApi(guid: string): Promise<AxiosResponse<boolean, any>> {
  return axiosInstance.get(`/service/shuffle_texts/${guid}`)
}

export function getCoinsApi(guid: string): Promise<AxiosResponse<number, any>> {
  return axiosInstance.get(`/service/get_coins/${guid}`)
}

export function generateBalancesApi(guid: string): Promise<AxiosResponse<number, any>> {
  return axiosInstance.get(`/service/generate_balances/${guid}`)
}

export function addCoinsApi(guid: string): Promise<AxiosResponse<number, any>> {
  return axiosInstance.get(`/service/add_coins/${guid}`)
}

export function banPlayersApi(guid: string): Promise<AxiosResponse<number, any>> {
  return axiosInstance.get(`/service/ban_players/${guid}`)
}
