import cn from 'classnames'
import style from '../TextsAdmin.module.scss'
import { TextAdmin } from '../../../../types/types'
import { textsRatingBy_like, textsRatingBy_readable, textsRatingBy_style, textsRatingBy_theme, topTextsMethod } from '../TextsAdmin.methods'
import { ExtraVoteBlock } from '../../user/components/ExtraVoteBlock'
import { NominationsList } from '../../user/components/NominationsList'
import { statusDecoder } from '../../../../data/statusDecoder'

interface Props {
  textsAdmin: TextAdmin[]
}

export const AdminDetailTexts = (props: Props) => {
  const top_like = textsRatingBy_like(props.textsAdmin)
  const top_theme = textsRatingBy_theme(props.textsAdmin)
  const top_readable = textsRatingBy_readable(props.textsAdmin)
  const top_style = textsRatingBy_style(props.textsAdmin)
  const textSortedByRating = topTextsMethod(props.textsAdmin)

  return (
    <>
      <h2>Детально</h2>
      {props.textsAdmin.map((text, index) => {
        const ratingNumber = textSortedByRating.findIndex((text2) => text2.user_id === text.user_id) + 1

        const beeCoins = text.bets.filter((bet) => bet.coin === 'beecoin').reduce((acc, bet) => acc + bet.amount, 0)
        const watermelonCoins = text.bets.filter((bet) => bet.coin === 'watermeloncoin').reduce((acc, bet) => acc + bet.amount, 0)

        const guessPercent = text.guesses.total > 0 ? Math.ceil((text.guesses.correct / text.guesses.total) * 100) : 0
        const guessFromPercent = text.guessesFrom.total > 0 ? Math.ceil((text.guessesFrom.correct / text.guessesFrom.total) * 100) : 0

        const place_like = top_like.findIndex((_text) => _text.user_id === text.user_id) + 1
        const place_theme = top_theme.findIndex((_text) => _text.user_id === text.user_id) + 1
        const place_readable = top_readable.findIndex((_text) => _text.user_id === text.user_id) + 1
        const place_style = top_style.findIndex((_text) => _text.user_id === text.user_id) + 1

        return (
          <>
            <div
              className={cn(style.textBlock, {
                [style.gold]: ratingNumber === 1,
                [style.silver]: ratingNumber === 2,
                [style.bronze]: ratingNumber === 3,
              })}
              key={index}
            >
              <div className={style.texttitle}>
                <span>
                  #{index + 1}: «{text.title}»
                </span>
              </div>
              <div>
                <a href={text.link} target="_blank" rel="noopener noreferrer">
                  Ссылка на текст
                </a>
              </div>
              <div className={style.textStats}>
                <div>
                  Автор: {text.user} ({text.user_id})
                </div>
                <div>Реальный автор: {text.realUser}</div>
                <div>Статус: {statusDecoder[text.status]}</div>
                <div>
                  Голоса: {text.done.votes} | Оценки: {text.done.extraVotes} | Номинации: {text.done.nominations} | Ставки: {text.done.bets} | Рецензии:{' '}
                  {text.done.reviews}
                </div>
                <div>
                  Угадали: {text.guesses.correct} из {text.guesses.total} ({guessPercent}%)
                </div>
                <div>
                  Угадал других: {text.guessesFrom.correct} из {text.guessesFrom.total} ({guessFromPercent}%)
                </div>

                <hr />

                <div className={style.statsGrid4}>
                  <div className={style.statsCell}>
                    <div className={style.statTitle}>Голосов</div>
                    <div className={style.statValue}>{text.rating}</div>
                  </div>
                  <div className={style.statsCell}>
                    <div className={style.statTitle}>Место</div>
                    <div className={style.statValue}>#{ratingNumber}</div>
                  </div>
                  <div className={style.statsCell}>
                    <div className={style.statTitle}>🐝</div>
                    <div className={style.statValue}>{beeCoins}</div>
                  </div>
                  <div className={style.statsCell}>
                    <div className={style.statTitle}>🍉</div>
                    <div className={style.statValue}>{watermelonCoins}</div>
                  </div>
                </div>
                <hr />
                <div className={style.statsGrid4}>
                  <ExtraVoteBlock type="like" extraVotes={text.extraVotes} place={place_like} />
                  <ExtraVoteBlock type="theme" extraVotes={text.extraVotes} place={place_theme} />
                  <ExtraVoteBlock type="readable" extraVotes={text.extraVotes} place={place_readable} />
                  <ExtraVoteBlock type="style" extraVotes={text.extraVotes} place={place_style} />
                </div>
                <NominationsList nominations={text.nominations} nominationsWon={text.nominationsWon} />
                <div className={style.reviews}>
                  {text.reviewsTexts.map((review, index) => {
                    return (
                      <div key={index} className={style.review}>
                        от {review.userName} ({review.userRealName}): {review.text}
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </>
        )
      })}
    </>
  )
}
