export type PluralSet = [string, string, string]

type PluralSetCollection = {
  // [x: string]: PluralSet;
  balls: PluralSet
  votes: PluralSet
  people: PluralSet
  extraVotesGen: PluralSet
  texts: PluralSet
  textsGen: PluralSet
  characters: PluralSet
  nominations: PluralSet
  reviews: PluralSet
  beecoin: PluralSet
  watermeloncoin: PluralSet
  voteVerb: PluralSet
  days: PluralSet
  askarons: PluralSet
  pushki: PluralSet
  draniki: PluralSet
  beses: PluralSet
  achievements: PluralSet
  type: PluralSet
  players: PluralSet
}

export const pluralSets: PluralSetCollection = {
  balls: ['балл', 'балла', 'баллов'],
  votes: ['голос', 'голоса', 'голосов'],
  people: ['человек', 'человека', 'человек'],
  extraVotesGen: ['дополнительную оценку', 'дополнительные оценки', 'дополнительных оценок'],
  texts: ['текст', 'текста', 'текстов'],
  textsGen: ['текста', 'текстов', 'текстов'],
  characters: ['знак', 'знака', 'знаков'],
  nominations: ['номинация', 'номинации', 'номинаций'],
  reviews: ['рецензия', 'рецензии', 'рецензий'],
  beecoin: ['пчелокоин', 'пчелокоина', 'пчелокоинов'],
  watermeloncoin: ['арбузокоин', 'арбузокоина', 'арбузокоинов'],
  voteVerb: ['проголосовал', 'проголосовали', 'проголосовало'],
  days: ['день', 'дня', 'дней'],
  askarons: ['аскарон', 'аскарона', 'аскаронов'],
  pushki: ['пушок', 'пушка', 'пушков'],
  draniki: ['драник', 'драника', 'драников'],
  beses: ['бес', 'беса', 'бесов'],
  achievements: ['ачивка', 'ачивки', 'ачивок'],
  type: ['тип', 'типа', 'типов'],
  players: ['игрок', 'игрока', 'игроков'],
}

export const pluralText = (number: number, texts: PluralSet, textOnly?: boolean) => {
  let cases = [2, 0, 1, 1, 1, 2]

  if (textOnly) {
    return texts[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
  }

  return number + ' ' + texts[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]]
}

export function getDMY(datetime: Date) {
  if (!datetime) {
    return '???'
  }
  const date = Date.parse(String(datetime))
  const newdate = new Date(date)
  return newdate.toString().slice(4, 24)
}

export function compare(text: string, filterText: string) {
  if (!text || !filterText) return false
  return text.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
}
