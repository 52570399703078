import React from 'react'
import { date_voting_end } from '../../../data/gameConfig'
import { GameState } from '../../../store/game.slice'
import style from './Game.module.scss'
import { Tree } from './components/Tree'
import { localizeDate } from '../../../methods/date.methods'
import { AudioManager } from './components/AudioManager'
import { Kot } from './components/Kot'

interface Props {
  gameState: GameState
}

export const Game = (props: Props) => {
  const realNow = new Date()
  const offset = realNow.getTimezoneOffset() + 180 // Сдвиг часового пояса + 180 минут (3 часа от гринвича)
  const now = new Date(realNow.getTime() + offset * 60 * 1000)
  const toDeadline = date_voting_end.valueOf() - now.valueOf()

  const [timer, setTimer] = React.useState(toDeadline)
  const [audioTick, set_audioTick] = React.useState(0)

  // const [testRange, set_testRange] = React.useState(0)

  function handleTimer() {
    setTimeout(() => {
      const realNow = new Date()
      const offset = realNow.getTimezoneOffset() + 180 // Сдвиг часового пояса + 180 минут (3 часа от гринвича)
      const now = new Date(realNow.getTime() + offset * 60 * 1000)
      const toDeadline = date_voting_end.valueOf() - now.valueOf()
      setTimer(toDeadline)
      set_audioTick(audioTick + 1)
    }, 1000)
  }

  React.useEffect(() => {
    handleTimer()
    // eslint-disable-next-line
  }, [])

  React.useEffect(() => {
    handleTimer()
    // eslint-disable-next-line
  }, [audioTick])

  const voted = props.gameState.votes.done
  const total = props.gameState.votes.total
  // const votedPercent = (voted / total) * 100

  const extra_voted = props.gameState.extraVotes.done
  const extra_total = props.gameState.extraVotes.total
  // const extra_votedPercent = (extra_voted / extra_total) * 100

  const nominations_voted = props.gameState.nominations.done
  const nominations_total = props.gameState.nominations.total
  // const nominations_votedPercent = (nominations_voted / nominations_total) * 100

  const bets_voted = props.gameState.bets.done
  const bets_total = props.gameState.bets.total
  // const bets_votedPercent = (bets_voted / bets_total) * 100

  const reviews_voted = props.gameState.reviews.done
  const reviews_total = props.gameState.reviews.total
  // const reviews_votedPercent = (reviews_voted / reviews_total) * 100

  // const maxWidth = 600

  const hours = Math.floor(timer / 1000 / 60 / 60)
  const mins = Math.floor(timer / 1000 / 60) - hours * 60
  const secs = Math.floor(timer / 1000) - hours * 60 * 60 - mins * 60

  // const totalDone = props.gameState.votes.done + props.gameState.extraVotes.done + props.gameState.nominations.done + props.gameState.bets.done;
  // const totalTasks = props.gameState.votes.total + props.gameState.extraVotes.total + props.gameState.nominations.total + props.gameState.bets.total;
  const totalDone =
    props.gameState.votes.done + props.gameState.extraVotes.done + props.gameState.nominations.done + props.gameState.bets.done + +props.gameState.reviews.done
  const totalTasks =
    props.gameState.votes.total +
    props.gameState.extraVotes.total +
    props.gameState.nominations.total +
    props.gameState.bets.total +
    props.gameState.reviews.total
  const percent = Math.ceil((totalDone / totalTasks) * 100)

  const voting_end = localizeDate(date_voting_end)

  return (
    <div className={style.game}>
      <AudioManager tick={audioTick} type="clock" />

      <div className={style.progress}>
        <ProgressBar title="Голосов" voted={voted} total={total} />
        <ProgressBar title="Оценки" voted={extra_voted} total={extra_total} />
        <ProgressBar title="Номинации" voted={nominations_voted} total={nominations_total} />
        <ProgressBar title="Ставки" voted={bets_voted} total={bets_total} />
        <ProgressBar title="Рецензии" voted={reviews_voted} total={reviews_total} />
      </div>

      <div className={style.instruction}>
        <div>Блины были повреждены в ходе хакерской атаки!</div>
        <div>
          Тыканье кнопочек поможет вычислить ошибки, <br /> чтобы блины починились!
        </div>
      </div>

      {/* <input type="range" value={testRange} onChange={(event) => set_testRange(parseInt(event.target.value))} /> */}

      {/* <Tree percent={percent} /> */}
      <Kot percent={percent} />

      <div className={style.percent}>
        <div className={style.title}>Вычисление блинов</div>
        <div className={style.number}>{percent}%</div>
        <div className={style.title2}>Нужно 75%, чтобы восстановить блины!</div>
      </div>

      <div className={style.deadline}>
        <h3>Дедлайн голосования {voting_end} в 23:59:59</h3>
        {hours === 0 && (
          <div className={style.warning}>
            <div>☢️</div>
            <div>И он очень близок</div>
            <div>☢️</div>
          </div>
        )}
        {hours < 0 && (
          <div className={style.warning}>
            <div>💀 И он закончился 💀</div>
          </div>
        )}
        {hours > -1 && (
          <div className={style.timer}>
            <div className={style.number}>{hours}</div>
            <div>:</div>
            <div className={style.number}>{mins < 10 ? `0${mins}` : mins}</div>
            <div>:</div>
            <div className={style.number}>{secs < 10 ? `0${secs}` : secs}</div>
          </div>
        )}
      </div>
    </div>
  )
}

interface ProgressBarProps {
  title: string
  voted: number
  total: number
}
const ProgressBar = (props: ProgressBarProps) => {
  const percent = Math.floor((props.voted / props.total) * 100)
  return (
    <div className={style.progressBar}>
      <div className={style.title}>
        {props.title}: {props.voted} из {props.total}
      </div>
      <div className={style.await}></div>
      <div className={style.filled} style={{ width: `${percent}%` }}></div>
    </div>
  )
}
